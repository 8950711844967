import { ResponsePojo } from "src/app/pojo/response.pojo";
import { MarriageApplicationPojo } from "./marriage-application.pojo";
import { UserTransactionHistoryPojo } from "./UserTransactionHistoryPojo";

export class DummyMarriageBookingTransactionPojo {
    marriageApplicationPojo = new MarriageApplicationPojo();
    transaction = new UserTransactionHistoryPojo();
    marriageTypeName: string; 
    marriageRegionName: string;
    responsePojo = new ResponsePojo();
    pgProvider: string;
    // iobTransactionRequestPojo = new IobTransactionRequestPojo();
}

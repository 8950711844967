import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoomRegionPojo } from '../pojo/RoomRegion.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { Observable } from 'rxjs';
import { RoomTypeMasterPojo } from '../pojo/room-type-master.pojo';
import { DummyRoomTypeRoomPojo } from 'src/app/receipt/receipt-pojo/DummyRoomType.pojo';
import { CottageMasterPojo } from '../pojo/cottage-master.pojo';

@Injectable({
  providedIn: 'root'
})
export class RoomRegionService {

  constructor(private httpClient: HttpClient) { }
  addRoomRegion(arg: RoomRegionPojo): Observable<RoomRegionPojo> {
    const url = StaticVariable.porturl + "AddRoomRegion";
    return this.httpClient.post<RoomRegionPojo>(url, arg);
}

updateRoomRegion(arg: RoomRegionPojo):Observable<boolean>{
    const url = StaticVariable.porturl + 'RoomRegionUpdate';
    return this.httpClient.put<boolean>(url, arg);
  }

  deleteRoomRegionById(regionid:Number):Observable<any>{
      const url =StaticVariable.porturl + 'RoomRegionDelete/'+ regionid;
      return this.httpClient.delete<any>(url)
  }
  getAllRoomRegion():Observable<RoomRegionPojo[]>{
      const url = StaticVariable.porturl +'RoomRegionAll';
      return this.httpClient.get<RoomRegionPojo[]>(url);
  }
  getRoomTypeMasterRegionId(regionId:number):Observable<DummyRoomTypeRoomPojo[]>{
    const url = StaticVariable.porturl + 'RoomTypeMasterByRegionId/' + regionId;
    return this.httpClient.get<DummyRoomTypeRoomPojo[]>(url);
  }


  getCottageMasterByRegionId(regionId:number):Observable<CottageMasterPojo[]>{
     const url = StaticVariable.porturl + 'cottageMasterByRegionId/' + regionId;
    return this.httpClient.get<CottageMasterPojo[]>(url);
  }


}

export class ResponsePojo {

    responseId: number;
    responseText: string;
    responseBoolean: boolean;
    responseCode: number;
    status: string;
    details: string;

}

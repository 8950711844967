import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo, ManagementRolePojo } from 'src/app/pojo/management-user-pojo';
import { MRolePojo } from 'src/app/pojo/role-master.pojo';
import { RoleCreationService } from '../../Services/role-creation.service';

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-role-creation',
    templateUrl: './role-creation.component.html',
    styleUrls: ['./role-creation.component.scss']
})

export class RoleCreationComponent implements OnInit {

    loggedUser = new MUserMasterPojo();
    roleMasterArr: MRolePojo[] = [];
    roleMaster = new MRolePojo();
    addRoleFun = new ManagementRolePojo();
    editRoleFun = new ManagementRolePojo();

    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean;

    constructor(private roleCreationService: RoleCreationService) { }

    ngOnInit() {
        this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));

        this.getMRoleAll();
        $('#addAfterClick').hide();
        $('#deleteAfterClick').hide();
        $('#editAfterClick').hide();
    }

    getMRoleAll() {
        this.isLoading = true;
        this.roleCreationService.getMRoleAll().subscribe(response => {
            this.roleMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('M Role All Service Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }

    onChange(event: any) {
        if ((<HTMLInputElement>document.getElementById('chkMaster')).checked) {
            (<HTMLInputElement>document.getElementById('chkDivotee')).checked = true;
            (<HTMLInputElement>document.getElementById('chkBookingMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkTicketMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkLocationMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkFestivalMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkCheckPointMaster')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkMaster')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkDivotee')).checked = false;
            (<HTMLInputElement>document.getElementById('chkBookingMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkTicketMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkLocationMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkFestivalMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkCheckPointMaster')).checked = false;
        }

    }

    onChangeChildElementInMaster(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkDivotee')).checked &&
            (<HTMLInputElement>document.getElementById('chkBookingMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkTicketMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkLocationMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkFestivalMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkCheckPointMaster')).checked) {
            (<HTMLInputElement>document.getElementById('chkMaster')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkMaster')).checked = false;
        }
    }

    onChangeAdmin(eventChange: any) {
        if ((<HTMLInputElement>document.getElementById('chkAdmin')).checked) {
            (<HTMLInputElement>document.getElementById('chkRoleMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkUsercreation')).checked = true;
            (<HTMLInputElement>document.getElementById('chkBackup')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkAdmin')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkRoleMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkUsercreation')).checked = false;
            (<HTMLInputElement>document.getElementById('chkBackup')).checked = false;
        }
    }

    onChangeChildElementInAdmin(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkRoleMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkUsercreation')).checked &&
            (<HTMLInputElement>document.getElementById('chkBackup')).checked) {
            (<HTMLInputElement>document.getElementById('chkAdmin')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkAdmin')).checked = false;
        }
    }

    onChangeReport() {
        if ((<HTMLInputElement>document.getElementById('chkReport')).checked) {
            (<HTMLInputElement>document.getElementById('chkDaywise')).checked = true;
            (<HTMLInputElement>document.getElementById('chkStatus')).checked = true;
            (<HTMLInputElement>document.getElementById('chkAbstract')).checked = true;
            (<HTMLInputElement>document.getElementById('chkLocationAbst')).checked = true;
            (<HTMLInputElement>document.getElementById('chkSales')).checked = true;
            (<HTMLInputElement>document.getElementById('chkSalesDetsils')).checked = true;
            (<HTMLInputElement>document.getElementById('chkTicketing')).checked = true;
            (<HTMLInputElement>document.getElementById('chkLocationWithDate')).checked = true;
            (<HTMLInputElement>document.getElementById('chkMonthwise')).checked = true;
            (<HTMLInputElement>document.getElementById('chkSalesAbst')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkReport')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkDaywise')).checked = false;
            (<HTMLInputElement>document.getElementById('chkStatus')).checked = false;
            (<HTMLInputElement>document.getElementById('chkAbstract')).checked = false;
            (<HTMLInputElement>document.getElementById('chkLocationAbst')).checked = false;
            (<HTMLInputElement>document.getElementById('chkSales')).checked = false;
            (<HTMLInputElement>document.getElementById('chkSalesDetsils')).checked = false;
            (<HTMLInputElement>document.getElementById('chkTicketing')).checked = false;
            (<HTMLInputElement>document.getElementById('chkLocationWithDate')).checked = false;
            (<HTMLInputElement>document.getElementById('chkMonthwise')).checked = false;
            (<HTMLInputElement>document.getElementById('chkSalesAbst')).checked = false;
        }
    }

    onChangeChildElementInReport(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkDaywise')).checked &&
            (<HTMLInputElement>document.getElementById('chkStatus')).checked &&
            (<HTMLInputElement>document.getElementById('chkAbstract')).checked &&
            (<HTMLInputElement>document.getElementById('chkLocationAbst')).checked &&
            (<HTMLInputElement>document.getElementById('chkSales')).checked &&
            (<HTMLInputElement>document.getElementById('chkSalesDetsils')).checked &&
            (<HTMLInputElement>document.getElementById('chkTicketing')).checked &&
            (<HTMLInputElement>document.getElementById('chkLocationWithDate')).checked &&
            (<HTMLInputElement>document.getElementById('chkMonthwise')).checked &&
            (<HTMLInputElement>document.getElementById('chkSalesAbst')).checked)
             {
            (<HTMLInputElement>document.getElementById('chkReport')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkReport')).checked = false;
        }
    }

    onchangeBooking(){
        if ((<HTMLInputElement>document.getElementById('chkBooking')).checked){
            (<HTMLInputElement>document.getElementById('chkBooking')).checked = true;
        }else{
            (<HTMLInputElement>document.getElementById('chkBooking')).checked = false;
        }
    }

    onchangeVerification(){
        if ((<HTMLInputElement>document.getElementById('chkVerification')).checked){
            (<HTMLInputElement>document.getElementById('chkVerification')).checked = true;
        }else{
            (<HTMLInputElement>document.getElementById('chkVerification')).checked = false;
        }
    }


    onChangeAndroid() {
        if ((<HTMLInputElement>document.getElementById('chkAndroid')).checked) {
            (<HTMLInputElement>document.getElementById('chkAndroidLogin')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkAndroid')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkAndroidLogin')).checked = false;
        }
    }

    onChangeChildElementInAndroid(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkAndroidLogin')).checked) {
            (<HTMLInputElement>document.getElementById('chkAndroid')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkAndroid')).checked = false;
        }
    }


    roleKeySet() {


        if ((<HTMLInputElement>document.getElementById('chkMaster')).checked) this.addRoleFun.master = true;
        if ((<HTMLInputElement>document.getElementById('chkDivotee')).checked) this.addRoleFun.divotee = true;
        if ((<HTMLInputElement>document.getElementById('chkBookingMaster')).checked) this.addRoleFun.bookingmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkTicketMaster')).checked) this.addRoleFun.ticketmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkLocationMaster')).checked) this.addRoleFun.locationmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkFestivalMaster')).checked) this.addRoleFun.festivalmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkCheckPointMaster')).checked) this.addRoleFun.checkpointmaster = true;


        if ((<HTMLInputElement>document.getElementById('chkAdmin')).checked) this.addRoleFun.admin = true;
        if ((<HTMLInputElement>document.getElementById('chkRoleMaster')).checked) this.addRoleFun.rolemaster = true;
        if ((<HTMLInputElement>document.getElementById('chkUsercreation')).checked) this.addRoleFun.usercreation = true;
        if ((<HTMLInputElement>document.getElementById('chkBackup')).checked) this.addRoleFun.backup = true;

        if ((<HTMLInputElement>document.getElementById('chkBooking')).checked) this.addRoleFun.bookingform = true;
        if ((<HTMLInputElement>document.getElementById('chkVerification')).checked) this.addRoleFun.verificationform = true;


        if ((<HTMLInputElement>document.getElementById('chkReport')).checked) this.addRoleFun.report = true;
        if ((<HTMLInputElement>document.getElementById('chkDaywise')).checked) this.addRoleFun.daywisereport = true;
        if ((<HTMLInputElement>document.getElementById('chkStatus')).checked) this.addRoleFun.statusreport = true;
        if ((<HTMLInputElement>document.getElementById('chkAbstract')).checked) this.addRoleFun.abstractreport = true;
        if ((<HTMLInputElement>document.getElementById('chkLocationAbst')).checked) this.addRoleFun.locationabstract = true;
        if ((<HTMLInputElement>document.getElementById('chkSales')).checked) this.addRoleFun.salesreport = true;
        if ((<HTMLInputElement>document.getElementById('chkSalesDetsils')).checked) this.addRoleFun.salesdetailsreport = true;
        if ((<HTMLInputElement>document.getElementById('chkTicketing')).checked) this.addRoleFun.ticketingreport = true;

        if ((<HTMLInputElement>document.getElementById('chkLocationWithDate')).checked) this.addRoleFun.locationwithdaywisereport = true;
        if ((<HTMLInputElement>document.getElementById('chkMonthwise')).checked) this.addRoleFun.monthwisereport = true;
        if ((<HTMLInputElement>document.getElementById('chkSalesAbst')).checked) this.addRoleFun.salesabstractreport = true;

        if ((<HTMLInputElement>document.getElementById('chkAndroid')).checked) this.addRoleFun.android = true;
        if ((<HTMLInputElement>document.getElementById('chkAndroidLogin')).checked) this.addRoleFun.androidlogin = true;

    }


    addRole() {

        this.roleKeySet();

        if (this.addRoleFun.rolemaster == true && this.addRoleFun.usercreation == true && this.addRoleFun.backup == true) {
            this.addRoleFun.admin = true;
        }

        if (this.addRoleFun.daywisereport == true && this.addRoleFun.statusreport == true && this.addRoleFun.abstractreport == true
            && this.addRoleFun.locationabstract == true && this.addRoleFun.salesdetailsreport == true && this.addRoleFun.salesreport == true
            && this.addRoleFun.ticketingreport == true && this.addRoleFun.locationwithdaywisereport == true && this.addRoleFun.monthwisereport == true
            && this.addRoleFun.salesabstractreport == true) {
            this.addRoleFun.report = true;
        }

        if (this.addRoleFun.divotee == true && this.addRoleFun.bookingmaster == true && this.addRoleFun.locationmaster == true && this.addRoleFun.ticketmaster == true && this.addRoleFun.festivalmaster == true && this.addRoleFun.checkpointmaster) {
            this.addRoleFun.master = true;
        }

        if(this.addRoleFun.bookingform == true ){
            this.addRoleFun.bookingform = true;
        }

        if(this.addRoleFun.verificationform == true){
            this.addRoleFun.verificationform = true;
        }

        if (this.addRoleFun.androidlogin == true) {
            this.addRoleFun.android = true;
        }

        $('#addBeforeClick').hide(); $('#addAfterClick').show();
        (<HTMLInputElement>document.getElementById('addButton')).disabled = true;
        let d = new MRolePojo();
        d.userId = this.loggedUser.userId;
        d.userRole = (<HTMLInputElement>document.getElementById('roleName')).value;
        d.roleKey = JSON.stringify(this.addRoleFun);
        this.roleCreationService.addMRole(d).subscribe(response => {
            bootbox.alert('Successfully Added');
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            (<HTMLFormElement>document.getElementById('addRole')).reset();
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
            $('#addModal').modal('hide');
            this.getMRoleAll();
        }, error => {
            bootbox.alert('Add M Role Service Error! - HTTP Status ' + error.status);
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
        });
    }

    clickupdate(arg: number) {
        this.id = arg;
        const i = this.roleMasterArr.indexOf(this.roleMasterArr.find(r => r.roleId == this.id));
        (<HTMLFormElement>document.getElementById('editRoleName')).value = this.roleMasterArr[i].userRole;
        this.editRoleFun = JSON.parse(this.roleMasterArr[i].roleKey);
    }

    //

    onChangeMasterEdit() {
        if ((<HTMLInputElement>document.getElementById('chkEditMaster')).checked) {
            (<HTMLInputElement>document.getElementById('chkEditDivotee')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditBookingMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditTicketMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditLocationMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditFestivalMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditCheckPointMaster')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkEditMaster')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkEditDivotee')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditBookingMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditTicketMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditLocationMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditFestivalMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditCheckPointMaster')).checked = false;
        }

    }

    onChangeChildElementInEditMaster(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkEditDivotee')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditBookingMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditTicketMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditLocationMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditFestivalMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditCheckPointMaster')).checked) {
            (<HTMLInputElement>document.getElementById('chkEditMaster')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkEditMaster')).checked = false;
        }
    }



    onChangeEditAdmin(eventChange: any) {
        if ((<HTMLInputElement>document.getElementById('chkEditAdmin')).checked) {
            (<HTMLInputElement>document.getElementById('chkEditRoleMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditUsercreation')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditBackup')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkEditAdmin')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkEditRoleMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditUsercreation')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditBackup')).checked = false;
        }
    }

    onChangeChildElementInEditAdmin(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkEditRoleMaster')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditUsercreation')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditBackup')).checked) {
            (<HTMLInputElement>document.getElementById('chkEditAdmin')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkEditAdmin')).checked = false;
        }
    }


    onChangeEditReport() {
        if ((<HTMLInputElement>document.getElementById('chkEditReport')).checked) {
            (<HTMLInputElement>document.getElementById('chkEditDaywise')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditStatus')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditAbstract')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditLocationAbst')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditSales')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditSalesDetsils')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditTicketing')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditLocationWithDate')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditMonthwise')).checked = true;
            (<HTMLInputElement>document.getElementById('chkEditSalesAbst')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkEditReport')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkEditDaywise')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditStatus')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditAbstract')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditLocationAbst')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditSales')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditSalesDetsils')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditTicketing')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditLocationWithDate')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditMonthwise')).checked = false;
            (<HTMLInputElement>document.getElementById('chkEditSalesAbst')).checked = false;
        }
    }


    onChangeChildElementInEditReport(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkEditDaywise')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditStatus')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditAbstract')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditLocationAbst')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditSales')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditSalesDetsils')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditTicketing')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditLocationWithDate')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditMonthwise')).checked &&
            (<HTMLInputElement>document.getElementById('chkEditSalesAbst')).checked)
             {
            (<HTMLInputElement>document.getElementById('chkEditReport')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkEditReport')).checked = false;
        }
    }

    onChangeEditAndroid() {
        if ((<HTMLInputElement>document.getElementById('chkEditAndroid')).checked) {
            (<HTMLInputElement>document.getElementById('chkEditAndroidLogin')).checked = true;
        } else if ((<HTMLInputElement>document.getElementById('chkEditAndroid')).checked == false) {
            (<HTMLInputElement>document.getElementById('chkEditAndroidLogin')).checked = false;
        }
    }

    onChangeChildElementInEditAndroid(eventChange: any) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('chkEditAndroidLogin')).checked) {
            (<HTMLInputElement>document.getElementById('chkEditAndroid')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('chkEditAndroid')).checked = false;
        }
    }

    //


    updateRoleKeySet(){
        if ((<HTMLInputElement>document.getElementById('chkEditDivotee')).checked) this.editRoleFun.divotee = true;
        if ((<HTMLInputElement>document.getElementById('chkEditBookingMaster')).checked) this.editRoleFun.bookingmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkEditTicketMaster')).checked) this.editRoleFun.ticketmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkEditLocationMaster')).checked) this.editRoleFun.locationmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkEditFestivalMaster')).checked) this.editRoleFun.festivalmaster = true;
        if ((<HTMLInputElement>document.getElementById('chkEditCheckPointMaster')).checked) this.editRoleFun.checkpointmaster = true;

        if ((<HTMLInputElement>document.getElementById('chkEditRoleMaster')).checked) this.editRoleFun.rolemaster = true;
        if ((<HTMLInputElement>document.getElementById('chkEditUsercreation')).checked) this.editRoleFun.usercreation = true;
        if ((<HTMLInputElement>document.getElementById('chkEditBackup')).checked) this.editRoleFun.backup = true;

            if ((<HTMLInputElement>document.getElementById('chkEditBookingForm')).checked) this.editRoleFun.bookingform = true;
            if ((<HTMLInputElement>document.getElementById('chkEditVerification')).checked) this.editRoleFun.verificationform = true;


            if ((<HTMLInputElement>document.getElementById('chkEditDaywise')).checked) this.editRoleFun.daywisereport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditAbstract')).checked) this.editRoleFun.abstractreport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditStatus')).checked) this.editRoleFun.statusreport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditLocationAbst')).checked) this.editRoleFun.locationabstract = true;
            if ((<HTMLInputElement>document.getElementById('chkEditSales')).checked) this.editRoleFun.salesreport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditSalesDetsils')).checked) this.editRoleFun.salesdetailsreport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditTicketing')).checked) this.editRoleFun.ticketingreport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditLocationWithDate')).checked) this.editRoleFun.locationwithdaywisereport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditMonthwise')).checked) this.editRoleFun.monthwisereport = true;
            if ((<HTMLInputElement>document.getElementById('chkEditSalesAbst')).checked) this.editRoleFun.salesabstractreport = true;

            if ((<HTMLInputElement>document.getElementById('chkEditAndroid')).checked) this.editRoleFun.android = true;
            if ((<HTMLInputElement>document.getElementById('chkEditAndroidLogin')).checked) this.editRoleFun.androidlogin = true;

    }


    updateRole() {

        this.updateRoleKeySet();

        if (this.editRoleFun.divotee == true && this.editRoleFun.checkpointmaster == true && this.editRoleFun.bookingmaster == true && this.editRoleFun.tickettype == true && this.editRoleFun.locationmaster == true && this.editRoleFun.ticketmaster == true && this.editRoleFun.festivalmaster == true) {
            this.editRoleFun.master = true;
        }
        if (this.editRoleFun.rolemaster == true && this.editRoleFun.usercreation == true && this.editRoleFun.backup == true) {
            this.editRoleFun.admin = true;
        }

        if(this.editRoleFun.bookingform == true){
            this.editRoleFun.bookingform = true;
        }

        if(this.editRoleFun.verificationform == true){
            this.editRoleFun.bookingform = true;
        }

        if (this.editRoleFun.daywisereport == true && this.editRoleFun.statusreport == true && this.editRoleFun.abstractreport == true
            && this.editRoleFun.locationabstract == true && this.editRoleFun.salesdetailsreport == true && this.editRoleFun.salesreport == true
            && this.editRoleFun.ticketingreport == true && this.editRoleFun.locationwithdaywisereport == true && this.editRoleFun.monthwisereport == true
            && this.editRoleFun.salesabstractreport == true) {
            this.editRoleFun.report = true;
        }
        if (this.editRoleFun.androidlogin == true) {
            this.editRoleFun.android = true;
        }
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        (<HTMLInputElement>document.getElementById('editButton')).disabled = true;
        let d = new MRolePojo();
        const i = this.roleMasterArr.indexOf(this.roleMasterArr.find(r => r.roleId == this.id));
        d.roleId = this.roleMasterArr[i].roleId;
        d.userRole = (<HTMLFormElement>document.getElementById('editRoleName')).value;
        d.roleKey = JSON.stringify(this.editRoleFun);
        d.userId = this.loggedUser.userId;
        this.roleCreationService.updateMRole(d).subscribe(response => {
            bootbox.alert('Successfully Updated');
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
            $('#editModal').modal('hide');
            this.getMRoleAll();
        }, error => {
            bootbox.alert('Update M Role Service Error! - HTTP Status ' + error.status);
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
        });
    }

    deleteClick(id: number) {
        this.id = id;
    }

    delete() {
        $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        this.roleCreationService.deleteMRole(this.id, 1, this.loggedUser.userId).subscribe(login1 => {
            if (login1 === 101) {
                bootbox.alert('Cannot Delete - This role linked to User');
            }
            else if (login1 === 0) {
                bootbox.alert('Already deleted');
            } else {
                bootbox.alert({ message: 'Deleted', className: 'text-danger', closeButton: 'false' });
                this.getMRoleAll();
            }
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            $('#deleteModal').modal('hide');
        }, error => {
            bootbox.alert('Error');
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }

}

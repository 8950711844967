import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TicketMasterPojo } from 'src/app/pojo/ticket-master.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { CheckPointMasterPojo } from 'src/app/pojo/checkpoint-master-pojo';
import { AbstractTicketSlotDetailsPojo } from 'src/app/pojo/abstractslotdetail-pojo';
import { BookingUserReportPojo } from 'src/app/pojo/booking-user-report.pojo';
import { ReceiptTypePojo } from 'src/app/pojo/receiptType.pojo';

@Injectable({
  providedIn: 'root'
})

export class TicketMasterService {

    constructor(private httpclient: HttpClient) { }

    getTicketAll(): Observable<TicketMasterPojo[]> {
        const url = StaticVariable.porturl + 'TicketMasterAll/';
        return this.httpclient.get<TicketMasterPojo[]>(url);
    }

    checkPointMasterAll(): Observable<CheckPointMasterPojo[]> {
        const url = StaticVariable.porturl + 'CheckPointMasterAll/';
        return this.httpclient.get<CheckPointMasterPojo[]>(url);
    }

    addTicket(arg: TicketMasterPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddTicketMaster';
        return this.httpclient.post(url, arg);
    }

    updateTicket(arg: TicketMasterPojo): Observable<any> {
        const url = StaticVariable.porturl + 'TicketMasterUpdate';
        return this.httpclient.put(url, arg);
    }

    deleteTicket(id: number, uid: string | number): Observable<any> {
        const url = StaticVariable.porturl + 'TicketMasterDelete/' + id + '/' + uid;
        return this.httpclient.delete<any>(url);
    }

    getTicketBylocation(locId: string | number, uid: string | number): Observable<TicketMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetTicketMasterByLocationIdAndUserId/' + locId + '/' + uid;
        return this.httpclient.get<TicketMasterPojo[]>(url);
    }

    getTicketSlotByTicketId(slotId: string | number): Observable<AbstractTicketSlotDetailsPojo[]> {
        const url = StaticVariable.porturl + 'TicketSlotDetailsByTicketId/' + slotId;
        return this.httpclient.get<AbstractTicketSlotDetailsPojo[]>(url);
    }

    getTicketSlotByTicketIdAndBookedDate(slotId: string | number, bookedDate: string): Observable<AbstractTicketSlotDetailsPojo[]> {
        const url = StaticVariable.porturl + 'TicketSlotDetailsByTicketIdAndDate/' + slotId + '/' + bookedDate;
        return this.httpclient.get<AbstractTicketSlotDetailsPojo[]>(url);
    }

    getBookingUserReport(locId: string | number, uid: string | number, tid: string | number, date: string): Observable<BookingUserReportPojo> {
        const url = StaticVariable.porturl + 'GetBookingUserReport/' + locId + '/' + uid + '/' + tid + '/' + date;
        return this.httpclient.get<BookingUserReportPojo>(url);
    }

    deleteTicketSlotDetails(id: number): Observable<any> {
        const url = StaticVariable.porturl + 'TicketSlotDetailsDeleteStatus/' + id;
        return this.httpclient.delete<any>(url);
    }

    getReceiptTypeAll(): Observable<ReceiptTypePojo[]> {
        const url = StaticVariable.porturl + 'GetReceiptTypeAll/';
        return this.httpclient.get<ReceiptTypePojo[]>(url);
    }

    getTicketByTempleId(templeId: number): Observable<TicketMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetOnlineTicketMasterByTempleId/' + templeId;
        return this.httpclient.get<TicketMasterPojo[]>(url);
    }

    getTicketMasterWithOnlineByTempleId(templeId: number): Observable<TicketMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetTicketMasterWithOnlineByTempleId/' + templeId;
        return this.httpclient.get<TicketMasterPojo[]>(url);
    }

}

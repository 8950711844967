import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { TicketMasterPojo, TicketSlotDetailsPojo, TicketCheckPointPojo } from 'src/app/pojo/ticket-master.pojo';
import { CheckPointMasterPojo } from 'src/app/pojo/checkpoint-master-pojo';
import { ReceiptTypePojo } from 'src/app/pojo/receiptType.pojo';
import { TemplePojo } from 'src/app/pojo/temple.pojo';
import { TicketMasterService } from '../../Services/ticket-master.service';
import { OtherService } from '../../Services/other.service';

import * as moment from 'moment';

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-ticket-master',
    templateUrl: './ticket-master.component.html',
    styleUrls: ['./ticket-master.component.scss']
})

export class TicketMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    slotarr: TicketMasterPojo[] = [];
    ticketArr: TicketMasterPojo[] = [];
    addTicket = new TicketMasterPojo();
    editTicket = new TicketMasterPojo();
    checkArr: CheckPointMasterPojo[];
    addSlot = new TicketSlotDetailsPojo();
    editSlot = new TicketSlotDetailsPojo();
    addSlotArr: TicketSlotDetailsPojo[] = [];
    editSlotArr: TicketSlotDetailsPojo[] = [];
    receiptTypeArr: ReceiptTypePojo[] = [];
    templeList: TemplePojo[] = [];

    id: number;

    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    isLoading: boolean = true;
    moreFlag: boolean;

    constructor(private ticketMasterService: TicketMasterService, private otherService: OtherService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

        $('#addAfterClick').hide();
        $('#editAfterClick').hide();
        $('#deleteAfterClick').hide();

        this.getTicketMasterAll();
        this.getCheckPointAll();
        this.getReceiptTypeAll();
        this.getTempleAll();

    }

    getReceiptTypeAll() {
        this.ticketMasterService.getReceiptTypeAll().subscribe(response => {
            this.receiptTypeArr = response;
        }, error => {
            bootbox.alert('Get Ticket All Error! - HTTP Status ' + error.status);
        });
    }

    getTempleAll() {
        this.otherService.getTempleAll().subscribe(response => {
            this.templeList = response;
            this.addTicket.templeId = this.templeList[0].templeId;
        }, error => {
            bootbox.alert('Temple All Error! - HTTP Status ' + error.status);
        });
    }

    getTicketMasterAll() {
        this.ticketMasterService.getTicketAll().subscribe(response => {
            this.ticketArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('Get Ticket All Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }

    getCheckPointAll() {
        this.ticketMasterService.checkPointMasterAll().subscribe(response => {
            this.checkArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('Get All Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }

    getMaxNoOfTicketById(id) {
        if (this.ticketArr.find(r => r.slotFlag == id)) {
            return this.ticketArr.find(r => r.slotFlag == id).slotFlag;
        } else {
            return id;
        }
    }

    timePipe(arg: string) {
        return moment(arg, 'HH:mm').format('hh:mm A');
    }

    clickAddBtn() {
        this.addTicket = new TicketMasterPojo();
        this.addTicket.ticketType = 'DIRECT';
        this.addTicket.ticketPageType = 'GENERAL';
        this.addTicket.status = 'ENABLE';
        this.addTicket.extraPersonFlag = true;
        for (let i = 0; i < this.templeList.length; i++) {
            if (i == 0) {
                this.addTicket.templeId = this.templeList[i].templeId;
                this.moreFlag = false;
            }
        }

    }

    addSlotMaster() {
        this.addSlot.fromTime = this.addSlot.fromTime;
        this.addSlot.toTime = this.addSlot.toTime;
        this.addSlotArr.push(this.addSlot);
        this.addSlot = new TicketSlotDetailsPojo();
    }

    addSlotMasterDelete(i) {
        this.addSlotArr.splice(i, 1);
    }

    submitAddSlot() {
        this.addTicket.slotList = this.addSlotArr;
        $('#addSlotModal').modal('hide');
    }

    addClass() {
        (<HTMLInputElement>document.getElementById('addButton')).disabled = true;
        $('#addBeforeClick').hide(); $('#addAfterClick').show();
        if (this.addTicket.slotList == [] || this.addTicket.slotList == null || this.addTicket.slotList == undefined) {
            this.addTicket.slotList = [];
        }
        this.addTicket.checkPointList = [];
        if (this.addTicket.verification == 'true') {
            for (let i = 0; i < this.checkArr.length; i++) {
                let checkMaster = new TicketCheckPointPojo();
                if ((<HTMLInputElement>document.getElementById('cpoint' + i)).checked == true) {
                    checkMaster.checkPoint = this.checkArr[i].checkPoint;
                    checkMaster.checkPointId = this.checkArr[i].checkPointId;
                    checkMaster.ticketName = this.addTicket.ticketName;
                    this.addTicket.checkPointList.push(checkMaster);
                }
                if (i == this.checkArr.length - 1) {
                    this.addTicket.userId = this.loggedUser.userId;
                    this.ticketMasterService.addTicket(this.addTicket).subscribe(response => {
                        let d = new TicketMasterPojo();
                        d = response;
                        if (d.code == 101) {
                            bootbox.alert(d.res);
                            $('#addBeforeClick').show(); $('#addAfterClick').hide();
                            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                        } else {
                            bootbox.alert('Successfully Added');
                            (<HTMLFormElement>document.getElementById('addDevoteeForm')).reset();
                            $('#addModal').modal('hide');
                            this.addSlot = new TicketSlotDetailsPojo();
                            this.addTicket = new TicketMasterPojo();
                            this.addSlotArr = [];
                            this.getTicketMasterAll();
                            $('#addBeforeClick').show(); $('#addAfterClick').hide();
                            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                            this.moreClick();
                        }
                    }, error => {
                        bootbox.alert('Add Service Error! - HTTP Status ' + error.status);
                        (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                        $('#addBeforeClick').show(); $('#addAfterClick').hide();
                    });
                }
            }
        } else {
            this.ticketMasterService.addTicket(this.addTicket).subscribe(response => {
                bootbox.alert('Successfully Added');
                (<HTMLFormElement>document.getElementById('addDevoteeForm')).reset();
                $('#addModal').modal('hide');
                this.addSlot = new TicketSlotDetailsPojo();
                this.addTicket = new TicketMasterPojo();
                this.addSlotArr = [];
                this.getTicketMasterAll();
                $('#addBeforeClick').show(); $('#addAfterClick').hide();
                (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            }, error => {
                bootbox.alert('Add Service Error! - HTTP Status ' + error.status);
                (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                $('#addBeforeClick').show(); $('#addAfterClick').hide();
            });
        }
    }

    clickEdit(arg) {
        this.editSlotArr = [];
        this.editTicket = Object.assign({}, arg);
        if (this.editTicket.slotList != null) {
            this.editSlotArr = this.editTicket.slotList;
        }
        for (let a = 0; a < this.checkArr.length; a++) {
            (<HTMLInputElement>document.getElementById('editcpoint' + a)).checked = false;
            for (let b = 0; b < this.editTicket.checkPointList.length; b++) {
                if (this.checkArr[a].checkPointId == this.editTicket.checkPointList[b].checkPointId) {
                    (<HTMLInputElement>document.getElementById('editcpoint' + a)).checked = true;
                }

            }
        }
        this.moreFlag = false;
    }

    getMaxNoOfTicket(id) {
        if (this.ticketArr.find(r => r.slotFlag == id)) {
            return this.ticketArr.find(r => r.slotFlag == id).slotFlag;
        } else {
            return id;
        }
    }

    editSlotMaster() {
        this.editSlot.ticketMasterId = this.editTicket.ticketMasterId;
        this.editSlot.fromTime = this.editSlot.fromTime;
        this.editSlot.toTime = this.editSlot.toTime;
        this.editSlot.slotId = 0;
        this.editSlotArr.push(this.editSlot);
        this.editSlot = new TicketSlotDetailsPojo();
    }

    editSlotMasterDelete(i, id) {
        this.ticketMasterService.deleteTicketSlotDetails(id).subscribe(response => {
            let no = response;
            if (no == 101) {
                bootbox.alert('You cannot Delete this ticket,this linked to someother!');
            } else {
                this.editSlotArr.splice(i, 1);
            }
        }, error => {
            bootbox.alert('Delete Service Error! - HTTP Status ' + error.status);
        });
    }

    submitEditSlot() {
        this.editTicket.slotList = [];
        this.editTicket.slotList = this.editSlotArr;
        $('#editSlotModal').modal('hide');
    }

    editClass() {
        (<HTMLInputElement>document.getElementById('editButton')).disabled = true;
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        this.editTicket.checkPointList = [];
        if (this.editTicket.slotList == [] || this.editTicket.slotList == null || this.editTicket.slotList == undefined) {
            this.editTicket.slotList = [];
        }
        if (this.editTicket.verification == "true") {
            for (let i = 0; i < this.checkArr.length; i++) {
                let checkMaster = new TicketCheckPointPojo();
                if ((<HTMLInputElement>document.getElementById('editcpoint' + i)).checked == true) {
                    checkMaster.checkPoint = this.checkArr[i].checkPoint;
                    checkMaster.checkPointId = this.checkArr[i].checkPointId;
                    checkMaster.ticketName = this.editTicket.ticketName;
                    checkMaster.ticketId = this.editTicket.ticketMasterId;
                    this.editTicket.checkPointList.push(checkMaster);
                    // locTicketMaster.locationTicketId = this.ticketArr[i].locationTicketId;
                }
                if (i == this.checkArr.length - 1) {
                    this.editTicket.userId = this.loggedUser.userId;
                    this.ticketMasterService.updateTicket(this.editTicket).subscribe(response => {
                        let d = new TicketMasterPojo();
                        d = response;
                        if (d.code == 101) {
                            bootbox.alert(d.res);
                            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                            $('#editBeforeClick').show(); $('#editAfterClick').hide();
                        } else {
                            bootbox.alert('Successfully updated.');
                            $('#editModal').modal('hide');
                            this.editSlotArr = [];
                            this.getTicketMasterAll();
                            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                            $('#editBeforeClick').show(); $('#editAfterClick').hide();
                        }

                    }, error => {
                        bootbox.alert('Update Service Error! - HTTP Status ' + error.status);
                        (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                        $('#editBeforeClick').show(); $('#editAfterClick').hide();
                    });
                }
            }
        }

        if (this.editTicket.verification == "false") {
            this.editTicket.userId = this.loggedUser.userId;
            this.ticketMasterService.updateTicket(this.editTicket).subscribe(response => {
                bootbox.alert('Successfully updated.');
                $('#editModal').modal('hide');
                this.editSlotArr = [];
                this.getTicketMasterAll();
                (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                $('#editBeforeClick').show(); $('#editAfterClick').hide();
            }, error => {
                bootbox.alert('Update Service Error! - HTTP Status ' + error.status);
                (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                $('#editBeforeClick').show(); $('#editAfterClick').hide();
            });
        }
    }

    delete(arg) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.ticketArr.indexOf(this.ticketArr.find(response => response.ticketMasterId == this.id));
        $('#deleteBeforeClick').hide(); $('#deleteAfterClick').show();
        this.ticketMasterService.deleteTicket(this.id, this.loggedUser.userId).subscribe(response => {
            no = response;
            if (no == 0) {
                bootbox.alert('This row is already deleted');
                this.ticketArr.splice(i, 1);
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } if (no == 101) {
                bootbox.alert('You cannot Delete this ticket,this linked to someother!');
            }
            else {
                this.ticketArr.splice(i, 1);
                bootbox.alert('Successfully deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            }
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            $('#deleteModal').modal('hide');
            this.getTicketMasterAll();
        }, error => {
            bootbox.alert('Delete Service Error! - HTTP Status ' + error.status);
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }

    moreClick() {
        this.moreFlag = !this.moreFlag;
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from 'src/app/globle.class';
import { FestivalPojo } from 'src/app/pojo/festival.pojo';

@Injectable({
    providedIn: 'root'
})

export class FestivalMasterService {

    constructor(private httpclient: HttpClient) { }

    getFestivalById(festivalId: number): Observable<FestivalPojo> {
        const url = StaticVariable.porturl + 'FestivalDayMasterById/' + festivalId;
        return this.httpclient.get<FestivalPojo>(url);
    }

    getFestival(): Observable<FestivalPojo[]> {
        const url = StaticVariable.porturl + 'FestivalDayMasterAll';
        return this.httpclient.get<FestivalPojo[]>(url);
    }

    addFestival(arg: FestivalPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddFestivalDayMaster';
        return this.httpclient.post(url, arg);
    }

    deleteFestival(id: number): Observable<any> {
        const url = StaticVariable.porturl + 'FestivalDayMasterDelete/' + id;
        return this.httpclient.delete<any>(url);
    }

    updateFestival(data: FestivalPojo): Observable<any> {
        const url = StaticVariable.porturl + 'FestivalDayMasterUpdate';
        return this.httpclient.put(url, data);
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TemplePojo } from 'src/app/pojo/temple.pojo';
import { StaticVariable } from 'src/app/globle.class';

@Injectable({
    providedIn: 'root'
})

export class OtherService {

    constructor(private httpClient: HttpClient) { }

    getTempleAll(): Observable<TemplePojo[]> {
        const url = StaticVariable.porturl + 'TempleAll';
        return this.httpClient.get<TemplePojo[]>(url);
    }

    checkUserpassword(userId: number, password: string): Observable<boolean> {
        const url = StaticVariable.porturl + 'CheckMUserPassword/' + userId + '/' + password;
        return this.httpClient.get<boolean>(url);
    }

    updateUserpassword(userId: number, newPassword: string): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateMUserPassword/' + userId + '/' + newPassword;
        return this.httpClient.put(url, userId);
    }
}

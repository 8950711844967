export class LocationPojo {
    locationId: number;
    location: string;
    ipAddr: string;
    userId: number;
    locationTicket: LocationTicketPojo[] = [];
    locationCheckPoint: LocationCheckPointPojo[] = [];
}

export class LocationTicketPojo {
    locationTicketId: number;
    locationId: number;
    ticketId: number;
    ticket: string;
    noOfTicketsPerDay: number;
}

export class LocationCheckPointPojo {
    locationCheckId: number;
    locationId: number;
    location: string;
    checkPointId: number;
    checkPoint: string;
}
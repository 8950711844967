import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export class UtilsFunction {

    static getNgbDateByDateObject(date: Date): NgbDate {
        return new NgbDate(moment(date).year(), moment(date).month() + 1, moment(date).date());
    }

    static getDateStringByNgbDate(date: NgbDate): string {
        return date.year + '-' + date.month + '-' + date.day;
    }

    static getDateByNgbDate(date: NgbDate): Date {
        return moment(date.year + '-' + date.month + '-' + date.day).toDate();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoomTypeMasterPojo } from '../pojo/room-type-master.pojo';
import { Observable } from 'rxjs';
import { StaticVariable } from 'src/app/globle.class';

@Injectable({
  providedIn: 'root'
})
export class RoomTypeMasterService {

  constructor(private httpClient:HttpClient) { }

  getAllRoomTypeMasterAll():Observable<RoomTypeMasterPojo[]>{
    const url = StaticVariable.porturl + 'RoomTypeMasterAll';
    return this.httpClient.get<RoomTypeMasterPojo[]>(url);
  }

  addRoomTypeMaster(arg:RoomTypeMasterPojo):Observable<boolean>{
    const url = StaticVariable.porturl + 'AddRoomTypeMaster';
    return this.httpClient.post<boolean>(url,arg);
  }

  updateRoomTypeMaster(arg:RoomTypeMasterPojo):Observable<boolean>{
    const url = StaticVariable.porturl + 'RoomTypeMasterUpdate';
    return this.httpClient.put<boolean>(url,arg);
  }

  deleteRoomTypeMaster(id:number):Observable<number>{
    const url = StaticVariable.porturl + 'RoomTypeMasterDelete/' + id;
    return this.httpClient.delete<number>(url);
  }




}

import * as moment from 'moment';

import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { BridePhoto } from '../extras/bride-photo';
import { GroomPhoto } from '../extras/groom-photo';
import { HeaderImages } from '../extras/header-images';

import { DummyMarriageBookingTransactionPojo } from '../receipt-pojo/dummy-marriage-booking-transaction.pojo';



pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class MarriageBookingReceipt {

    static marriageReceiptPdf(arg: DummyMarriageBookingTransactionPojo, transactionId: number,) {
        //
        let content: any[] = [];
        let body: any[] = [];
        let bodymark: any[] = [];
        let bodyheader1: any[] = [];
        let bodyheader: any[] = [];
        let bodycontent: any[] = [];
        let bodyfoot: any[] = [];
        let wholeBody: any[] = [];
        let body1: any[] = [];
        let body2: any[] = [];
        let body3: any[] = [];
        let body4: any[] = [];
        let body5: any[] = [];
        let body6: any[] = [];
        let body7: any[] = [];
        let body8: any[] = [];
        let body9: any[] = [];
        let empty: any[] = [];
        let bodyfooter1: any[] = [];

        empty.push(
            [
                { text: '', },
            ],


        );

        bodyheader1.push(
            [
                { text: 'ஸ்ரீ \n', border: [false, false, false, false] },
            ],
            [
                { image: HeaderImages.header, width: 60, height: 30 },
            ],

        );


        bodymark.push(

            [
                // { text: 'திருமணப்பதிவு, வீடியோ அனுமதி மற்றும் திருமணப்பதிவு நகல் கட்டண ரசீது எண் :' ,alignment: 'left', font: 'Sundaram' },
                { text: 'கட்டண ரசீது எண் : ' + arg.marriageApplicationPojo.receiptId, alignment: 'left', font: 'Sundaram' },
            ]

        )

        bodyheader.push(




            [
                { text: 'அருள்மிகு மாரியம்மன் திருக்கோயில்' + '\n', medium: true, fontSize: 20, },


            ],
            [
                { text: 'சமயபுரம் - 621112,' + ' திருச்சிராப்பள்ளி வட்டம் & மாவட்டம் ' + '\n', medium: true, fontSize: 12, },

            ],
            [
                { text: 'தொலைபேசி எண்: ' + '0431-2670460' + '\n', medium: true, fontSize: 12, },

            ],
            [
                { text: ' Website: www.samayapurammariammantemple.org   ' + 'E.mail:symmariamman@gmail.com ', fontSize: 12, },

            ],
            [
                { text: 'திருமணப் மண்டபம் பதிவிற்க்கான விண்ணப்பம் ', medium: true, fontSize: 25 },

            ],

        );



        body.push(
            [
                { text: '1.' },
                { text: 'மணமகன் பெயர் \n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.groomName, fontSize: 8 },
                { image: GroomPhoto.groomphoto, width: 80, height: 90, rowSpan: 4, margin: [12, 0, 0, 0] },
            ],
            [
                { text: '2.' },
                { text: 'மணமகனின் தகப்பனார் பெயர்\n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.groomFatherName, fontSize: 8 },
                {}
            ], [
            { text: '3.' },
            { text: 'மணமகனின் ஆதார்  எண் \n' },
            { text: ' : ' },
            { text: arg.marriageApplicationPojo.groomProofNumber, fontSize: 8 },
            {}
        ],
            [
                { text: '4.' },
                { text: 'முகவரி \n (பின் கோடு  எண்  உட்பட )\n\n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.groomAddress, fontSize: 8 },
                {}
            ],
            [
                { text: '5.' },
                { text: 'மதம் (சான்று நகல் இணைக்கவும் )\n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.groomRegion},
                {}
            ],
            [
                { text: '6.' },
                { text: 'பிறந்த தேதி / வயது \n(பிறந்த  தேதிக்கான சான்று நகல் இணைக்கவும்) \n\n' },
                { text: ' : ' },
                { text: moment(arg.marriageApplicationPojo.groomDob, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' / ' + arg.marriageApplicationPojo.groomAge, colSpan: 2 }, {}
            ],
            [
                { text: '7.' },
                { text: 'மணமகள் பெயர் \n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.brideName, fontSize: 8 },
                { image: BridePhoto.bridephoto, width: 80, height: 90, rowSpan: 4, margin: [12, 0, 0, 0] },
            ],
            [
                { text: '8.' },
                { text: 'மணமகளின் தகப்பனார் பெயர் \n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.brideFatherName, fontSize: 8 },
                {}
            ],
            [
                { text: '9.' },
                { text: 'மணமகளின்  ஆதார்  எண் \n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.brideProofNumber, fontSize: 8 },
                {}
            ],
            [
                { text: '10.' },
                { text: 'முகவரி \n(பின் கோடு  எண்  உட்பட ) \n\n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.brideAddress, fontSize: 8 },
                {}
            ],
            [
                { text: '11.' },
                { text: 'மதம் (சான்று நகல் இணைக்கவும் )\n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.brideRegion},
                {}
            ],
            [
                { text: '12.' },
                { text: 'பிறந்த தேதி / வயது ' + '\n' + ' (பிறந்த  தேதிக்கான சான்று நகல் இணைக்கவும்)\n\n' },
                { text: ' : ' },
                { text: moment(arg.marriageApplicationPojo.brideDob, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' / ' + arg.marriageApplicationPojo.brideAge, colSpan: 2 }, {}
            ],
            [
                { text: '13.' },
                { text: 'தொலைபேசி / அலைபேசி எண் \n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.mobileNo, colSpan: 2 }, {}
            ],
            [
                { text: '14.' },
                { text: 'திருமண தேதி / நேரம்' + '\n' + '(அழைப்பிதழ் இருப்பின் இணைக்கவும்) \n \n' },
                { text: ' : ' },
                { text: arg.marriageApplicationPojo.marriageDate + ' / ' + arg.marriageApplicationPojo.marriageTime, colSpan: 2 }, {}

            ],

        );

        bodycontent.push(
            [

                { text: 'மேற்கண்ட விவரங்கள் யாவும் உண்மை என உறுதி அளிக்கின்றோம் ' + '\n \n', medium: true, fontSize: 12 },
            ],
        );


        bodyfoot.push(
            [
                { text: 'மணமகன் கையொப்பம் \n\n', medium: true, font: 'Sundaram' },
                { text: '' },
                { text: 'மணமகள் கையொப்பம்   \n\n ', medium: true, fontSize: 12, },
                { text: '' }

            ], [
            { text: 'மணமகனின் தகப்பனார் / \n ' + 'காப்பாளர் கையொப்பம்.' + '\n', medium: true, fontSize: 12 },
            { text: '' },
            { text: 'மணமகளின் தகப்பனார் /  \n' + 'காப்பாளர் கையொப்பம்.' + '\n\n\n', medium: true, fontSize: 12 },
            { text: '' }

        ],
        );



        body1.push(
            [
                { text: '\n கிராம நிர்வாக அலுவலர்களின் சான்று  \n', medium: true, decoration: 'underline', fontSize: 20, },
            ],
            [
                { text: 'மணமகன்', medium: true, decoration: 'underline', fontSize: 20 },
            ],
        );

        body2.push(
            [
                { text: '.....................................  ................................ மாவட்டம்  ........................................................................வட்டம் ', medium: true, fontSize: 12 },
            ],
            [{ text: '.................................................................................வசிக்கும் ............................................................................... ', medium: true, fontSize: 12 },
            ],
            [{ text: 'குமாரர் ................................................................க்கு ...................................................................வயது(எழுத்தால்) ', medium: true, fontSize: 12 },
            ],
            [{ text: '.....................................................ஆகிறது.  மணமகன் இந்து மதத்தை சார்ந்தவர் எனவும், மணமகனுக்கு இது  ', medium: true, fontSize: 12 },
            ],
            [{ text: '...................................  (முதல்/இரண்டாம்) திருமணம் எனவும் முன்பக்கத்தில் குறிப்பிடப்பட்டுள்ள மணமகன் தொடர்பான விவரங்கள்  யாவும் உண்மை எனவும் சான்றளிக்கிறேன். \n \n\n ', medium: true, fontSize: 12 },
            ],
            // [
            //     { text: 'தொடர்பான விவரங்கள்  யாவும் உண்மை எனவும் சான்றளிக்கிறேன். \n \n\n', medium: true, fontSize: 12 },

            // ]


        );

        body4.push(
            [
                { text: 'முத்திரை  \n', medium: true, fontSize: 12, },
                { text: '' },
                { text: 'கிராம நிர்வாக அலுவலர்.\n\n', medium: true, fontSize: 12, },
                { text: '' },

            ],
        )


        body6.push(

            [
                { text: 'மணமகள்', medium: true, decoration: 'underline', fontSize: 20, border: [false, true, false, false] },

            ]

        );


        body3.push(
            [
                { text: '.....................................  ................................ மாவட்டம்  ........................................................................வட்டம் ', medium: true, fontSize: 12 },
            ],
            [{ text: '.................................................................................வசிக்கும் ............................................................................... ', medium: true, fontSize: 12 },
            ],
            [{ text: 'குமாரத்தி ...........................................................க்கு ...................................................................வயது(எழுத்தால்) ', medium: true, fontSize: 12 },
            ],
            [{ text: '.....................................................ஆகிறது.  மணமகள் இந்து மதத்தை சார்ந்தவர் எனவும், மணமகளுக்கு இது  ', medium: true, fontSize: 12 },
            ],
            [{ text: '...................................  (முதல்/இரண்டாம்) திருமணம் எனவும் முன்பக்கத்தில் குறிப்பிடப்பட்டுள்ள மணமகள் தொடர்பான விவரங்கள்  யாவும் உண்மை எனவும் சான்றளிக்கிறேன். \n \n\n ', medium: true, fontSize: 12 },
            ],
            // [
            //     { text: '.....................................  ................................ மாவட்டம்  ........................................................................வட்டம் ', medium: true, fontSize: 12 },
            // ],
            // [{ text: '.................................................................................வசிக்கும் ............................................................................... ', medium: true, fontSize: 12 },
            // ],
            // [{ text: 'குமாரத்தி ................................................................க்கு ...................................................................வயது(எழுத்தால்) ', medium: true, fontSize: 12 },
            // ],
            // [{ text: '.....................................................ஆகிறது.  மணமகள் இந்து மதத்தை சார்ந்தவர் எனவும், மணமகளுக்கு இது  ', medium: true, fontSize: 12 },
            // ],
            // [{ text: '...................................  (முதல் / இரண்டாம்) திருமணம் எனவும் முன்பக்கத்தில் குறிப்பிடப்பட்டுள்ள மணமகள் தொடர்பான விவரங்கள்  யாவும் உண்மை எனவும் சான்றளிக்கிறேன். \n \n\n ', medium: true, fontSize: 12 },
            // ],

            // [
            //     { text: '', medium: true, fontSize: 12 },

            // ]


        );



        body5.push(
            [
                { text: 'முத்திரை  \n', medium: true, fontSize: 12, },
                { text: '' },
                { text: 'கிராம நிர்வாக அலுவலர்.\n', medium: true, fontSize: 12, },
                { text: '' },

            ],

        ),

            body7.push(
                [
                    { text: 'குறிப்பு  \n\n', medium: true, decoration: 'underline', fontSize: 14, border: [false, true, false, false] },
                ]

            );

        body8.push(
            [
                { text: '1.' },
                { text: 'மணமகன் 21 வயது முடிந்தவராகவும், மணமகள் 18 வயது முடிந்தவராகவும் இருத்தல் வேண்டும். \n' },
            ],
            [
                { text: '2.' },
                { text: 'மணமகன், மணமகள் இருவரும் இந்து மதத்தை சார்ந்த இந்திய குடியுரிமை பெற்றவர்களாக இருத்தல் வேண்டும்.' }
            ],
            [
                { text: '3.' },
                { text: 'விண்ணப்பத்துடன் இணைக்கப்படும் அனைத்து சான்று நகல்களிலும் அரசிதழ் பதிவு பெற்ற அலுவலரின் சான்றொப்பம் செய்யப்பட்ட பின்னரே இணைக்கப்பட வேண்டும்.' }
            ],
            [
                { text: '4.' },
                { text: 'கிராம நிர்வாக அலுவலரின் கையொப்பம் மற்றும் முத்திரை இல்லாத விண்ணப்பம் நிராகரிக்கப்படும். மேலும் கிராம நிர்வாக அலுவலரின் சான்றில் திருத்தம் செய்யப்பட்டால் அவ்விண்ணப்பம் நிராகரிக்கப்படும்.' }
            ],
            [
                { text: '5.' },
                { text: 'திருமண நாளில் மணமகன், மணமகள் மணமகனின் பெற்றோர்/ காப்பாளர் மற்றும் மணமகளின் பெற்றோர்/ காப்பாளர் மற்றும் இரு சாட்சிகள் ஆகியோர் திருமணப் பதிவில் கட்டாயம் கையொப்பம் இடப்பட வேண்டும். தவறும் நிலையில் திருமணம் பதிவு செய்யப்பட மாட்டாது.' }
            ],
            [
                { text: '6.' },
                { text: 'திருமண விண்ணப்பத்தை திருமணத்திற்கு பத்து நாட்களுக்கு முன்பாக அலுவலகத்தில் ஒப்படைத்தவுடன் திருமணக்கட்டணமாக ரூ.1000/-, வீடியோ அனுமதி கட்டணம் ரூ. 250/- மற்றும் திருமண பதிவுநகல் கட்டணம் ரூ. 50/- ஆகக்கூடுதல் ரூ. 1300/-ஐ செலுத்தி திருக்கோயில் ரசீது பெற்றுக்கொள்ளவும். ' }
            ],
            [
                { text: '7.' },
                { text: 'திருமணப்பதிவு கட்டணம் ரூ.1000/- என்பது இடத்திற்கான வாடகைமட்டும்ஆகும். ' }
            ],
            [
                { text: '8.' },
                { text: 'புரோகிதர், புரோகிதற்கு தேவையான பொருட்கள், இதர திருமணத்திற்கு தேவையானவற்றை  தாங்களே எடுத்து வரவேண்டும்.' }
            ],
            [
                { text: '9.' },
                { text: 'மலை மீதுள்ள திருமணக்கூடத்தில் மட்டுமே திருமணம் நடத்திக்கொள்ள அனுமதிக்கப்படும்.' }
            ],
            [
                { text: '10.' },
                { text: 'திருமணம்முடித்த பின் திருமணப்பதிவு நகல் வேண்டுவோர் மனுவுடன் ரூ. 5 அஞ்சல் வில்லை ஒட்டிய சுய விலாசமிட்ட கவரை அலுவலகத்தில் கொடுக்க வேண்டும்.' }
            ],

            [
                { text: '11.' },
                { text: 'இடைத்தரகர்களை தவிர்க்க கேட்டுக் கொள்ளப்படுகிறது. \n\n' },


            ],



        )


        body9.push(

            [
                { text: 'எழுத்தர்/ மேலாளர்', medium: true, fontSize: 12, alignment: 'left', },
                { text: 'செயல் அலுவலர் ', medium: true, fontSize: 12, alignment: 'right' },

            ]

        );




        wholeBody.push(
            [
                {
                    'table': {
                        'body': empty,
                        headerRows: 1, widths: ['100%'],
                    }, fontSize: 10, alignment: 'center', font: 'Sundaram', layout: 'noBorders',
                },
            ],
            [
                {
                    'table': {
                        'body': bodyheader1,
                        headerRows: 1, widths: ['100%'],
                    }, fontSize: 10, alignment: 'center', font: 'Sundaram', layout: 'noBorders',
                },
            ],
            [
                {
                    'table': {
                        'body': bodymark,
                        headerRows: 1, widths: ['100%'],
                    }, fontSize: 10, alignment: 'left', font: 'Sundaram', layout: 'noBorders',
                },

            ],
            [
                {
                    'table': {
                        'body': bodyheader,
                        headerRows: 1, widths: ['100%'],
                    }, fontSize: 10, alignment: 'center', font: 'Sundaram', layout: 'noBorders',
                },
            ],
            [
                {
                    'table': {
                        'body': body,
                        headerRows: 1, widths: ['5%', '47%', '3%', '25%', '20%']
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'left'
                },
            ],
            [
                {
                    'table': {
                        'body': bodycontent,
                        headerRows: 1, widths: ['100%']
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'center'
                }
            ],
            [

                {
                    'table': {
                        'body': bodyfoot,
                        headerRows: 1, widths: ['30%', '35%', '33%', '2%'],
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders',
                }
            ],

            [
                {
                    'table': {
                        'body': body1,
                        headerRows: 1, widths: ['100%']
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'center'
                }
            ],
            [
                {

                    'table': {
                        'body': body2,
                        headerRows: 1, widths: ['*']
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'left'

                }
            ],
            [
                {
                    'table': {
                        'body': body4,
                        headerRows: 1, widths: ['30%', '35%', '33%', '2%'],
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'left'
                }
            ],
            [

                {
                    'table': {
                        'body': body6,
                        headerRows: 1, widths: ['100%']
                    }, fontSize: 12, font: 'Sundaram', alignment: 'center'
                }
            ],
            [
                {

                    'table': {
                        'body': body3,
                        headerRows: 1, widths: ['*']
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'left'

                }
            ],

            [
                {
                    'table': {
                        'body': body5,
                        headerRows: 1, widths: ['30%', '35%', '33%', '2%'],
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'left'
                }
            ],

            [
                {

                    'table': {
                        'body': body7,
                        headerRows: 1, widths: ['100%']
                    }, fontSize: 12, font: 'Sundaram', alignment: 'center'

                }
            ],
            [
                {

                    'table': {
                        'body': body8,
                        headerRows: 1, widths: ['5%', '95%']
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders', alignment: 'left'

                }
            ],

            [
                {

                    'table': {
                        'body': body9,
                        headerRows: 1, widths: ['50%', '50%']
                    }, fontSize: 12, font: 'Sundaram', layout: 'noBorders',

                }
            ],
        );

        content.push({
            'table': {
                'body': wholeBody,
                headerRows: 1, widths: ['100%'],
            }, fontSize: 10, alignment: 'center', font: 'Sundaram',
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 2 : 0;
                },
                vLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 2 : 0;
                },
            }
        });



        const PDF = {
            'content': content,
            'body': body,
            pageSize: "LEGAL",
            pageMargins: [30, 30, 30, 30]
        };
        pdfMake.createPdf(PDF).download();
        pdfMake.createPdf(PDF).open();

    }


}

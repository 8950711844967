import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogDetailsPojo } from 'src/app/pojo/log-details-pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { ReceiptService } from './receipt.service';
import { TransactionPojo } from 'src/app/pojo/transaction.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { OtpMasterPojo } from '../receipt-pojo/otp-master.pojo';
import { OtpMasterService } from '../extras/otp-master.service';
import { ResponseStatusPojo } from '../extras/response-status.pojo';
import { HundiDonationReceipt } from '../receipt-pdf/hundidonation-receipt';
import { GeneralDonationReceipt } from '../receipt-pdf/generaldonation-receipt';
import { AnadhaanamDonationReceipt } from '../receipt-pdf/annathanamdonation-receipt';
import { RoomBookingReceipt } from '../receipt-pdf/roombooking-receipt';
import { TicketBookingReceipt } from '../receipt-pdf/ticket-booking-receipt';
import { TirupaniDonationReceipt } from '../receipt-pdf/tirupanidonation.receipt';
import { qrPojo } from './receipt-qr.pojo';
import { DummyRoomBookingTransactionPojo } from '../receipt-pojo/DummyRoomBookingTransactionPojo';
import { DummyHundiTransactionPojo } from '../receipt-pojo/DummyHundiTransactionPojo';
import { DummyDonationTransactionPojo } from '../receipt-pojo/DummyDonationTransactionPojo';
import { DummyThirupaniDonationTransactionPojo } from '../receipt-pojo/DummyThirupaniDonationTransactionPojo';
import { TransactionTicketBookingPojo } from 'src/app/pojo/transaction-ticket-booking.pojo';
import { MarriageBookingReceipt } from '../receipt-pdf/marriage-booking-receipt';
import { DummyMarriageBookingTransactionPojo } from '../receipt-pojo/dummy-marriage-booking-transaction.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-receipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss']
})

export class ReceiptComponent implements OnInit {

    logpojo = new LogDetailsPojo();
    loggedUser = new MUserMasterPojo();
    otpMaster = new OtpMasterPojo();

    transactions: TransactionPojo[] = [];
    transaction = new TransactionPojo();
    responseStatus = new ResponseStatusPojo();

    qrpojo = new qrPojo();

    roomReceipt = new DummyRoomBookingTransactionPojo();
    hundiReceipt = new DummyHundiTransactionPojo();
    generalReceipt = new DummyDonationTransactionPojo();
    annathanamReceipt = new DummyDonationTransactionPojo();
    tirupaniReceipt = new DummyThirupaniDonationTransactionPojo();
    tickebookingReceipt = new TransactionTicketBookingPojo();
    marriageApplicationPdf = new DummyMarriageBookingTransactionPojo();

    downloadFlag: boolean;

    mobileNoC: any;
    ran_num: number;
    moblieNo: string;
    transactionId: number;
    tId: number;

    otpFlag: boolean;
    txnachk: boolean = false;
    managementUser: boolean = false;
    directPrintFlag: boolean = false;
    transactionIdFlag: boolean = true;
    getOtpFlag: boolean;
    mobileFlag: boolean;
    emailFlag: boolean;

    constructor(private route: ActivatedRoute, private receiptService: ReceiptService, private otpMasterService: OtpMasterService) { }

    ngOnInit() {

        $('#verifyOtpLoading').addClass('fa fa-spinner fa-spin');
        this.route.params.subscribe(params => {
            if (params['pathvariable'] == 'download') {
                this.directPrintFlag = false;
            } else {
                this.directPrintFlag = true;
                if (params['pathvariable'] != 'mdownload') {
                    if (localStorage.getItem('loggedUser')) {
                        this.transactionId = + localStorage.getItem('loggedUser');
                        this.directCheck(this.transactionId);
                    } else {
                        bootbox.alert("Cannot download reciept");
                    }
                } else {
                    if (sessionStorage.getItem('loggedUser')) {
                        this.managementUser = true;
                        this.logpojo.logDescription = "try to download using transaction id => via management receipt download";
                        this.logpojo.userId = this.loggedUser.userId;
                        this.logpojo.name = this.loggedUser.name;
                        this.logpojo.logName = "try to receipt download";
                        //   this.receiptService.addLogDetails(this.logpojo).subscribe(res => { }, error => { bootbox.alert('Error ' + error.status); });
                    }
                }
            }
        });
    }


    check(txnid: number) {
        this.logpojo.logDescription = "check transaction id => via management receipt download";
        this.logpojo.userId = this.loggedUser.userId;
        this.logpojo.name = this.loggedUser.name;
        this.logpojo.logName = "check transaction id valid or not";
        this.receiptService.addLogDetails(this.logpojo).subscribe(() => { }, error => { bootbox.alert('error ' + error.status); });
        this.transactionIdFlag = true;
        this.txnachk = false;
        this.transactionId = txnid;
        this.receiptService.getUserTransaction(this.transactionId).subscribe(log => {
            (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = true;
            $('#verifyOtpLoading').addClass('fa fa-spinner fa-spin');
            this.transaction = log;
            this.qrpojo.txnid = this.transaction.transactionId;
            this.qrpojo.txnamount = this.transaction.amount;
            this.qrpojo.txndate = this.transaction.bookedOn;
            this.qrpojo.bankrefno = this.transaction.pgBankRefNum;
            if (this.transactionId == this.transaction.transactionId) {
                if (this.transaction.status == StaticVariable.status_2) {
                    this.transactionIdFlag = false;
                    this.txnachk = false;
                    this.otpFlag = true;
                } else {
                    if (this.transaction.status == StaticVariable.status_1) {
                        bootbox.alert('Your Transaction is pending..');
                    } else {
                        bootbox.alert('Your Transaction is failed..');
                    }
                    (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
                    $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
                }
            } else {
                bootbox.alert('Invalid Transaction Id..');
                (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
                $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
                this.txnachk = true;
            }
        }, error => {
            bootbox.alert('Error ' + error.status);
        });
    }

    //   getOtp(){
    //     if (this.transaction.eServiceId == 1) {
    //       this.receiptService.gethundiReceipt(this.transactionId).subscribe(log1 => {
    //           this.hundiReceipt = log1;
    //           this.sendOTP(this.transaction.transactionId, this.hundiReceipt.hundiDetail.mobileNumber, this.hundiReceipt.hundiDetail.emailId);
    //           (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
    //           $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
    //       }, error => {
    //           bootbox.alert('Error ' + error.status);
    //       });
    //   } else if (this.transaction.eServiceId == 2) {
    //       this.receiptService.getGeneralDonationReceipt(this.transactionId).subscribe(log => {
    //           this.generalReceipt = log;
    //           this.sendOTP(this.transaction.transactionId, this.generalReceipt.donation.mobileNumber, this.generalReceipt.donation.emailId);
    //           (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
    //           $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
    //       }, error => {
    //           bootbox.alert('Error ' + error.status);
    //       });
    //   } else if (this.transaction.eServiceId == 3) {
    //       this.receiptService.getAnnathanamReceipt(this.transactionId).subscribe(log => {
    //           this.annathanamReceipt = log;
    //           this.sendOTP(this.transaction.transactionId, this.annathanamReceipt.donation.mobileNumber, this.annathanamReceipt.donation.emailId);
    //           (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
    //           $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
    //       }, error => {
    //           bootbox.alert('Error ' + error.status);
    //       });
    //   } else if (this.transaction.eServiceId == 4) {
    //       this.receiptService.getRoomReceipt(this.transactionId).subscribe(log => {
    //           this.roomReceipt = log;
    //           this.sendOTP(this.transaction.transactionId, this.roomReceipt.RoomBookingPojo.mobileNumber, this.roomReceipt.RoomBookingPojo.emailId);
    //           (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
    //           $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
    //       }, error => {
    //           bootbox.alert('Error ' + error.status);
    //       });
    //   }else if(this.transaction.eServiceId == 5){

    //     this.receiptService.getReceiptByTransactionId(this.transactionId).subscribe(log =>{
    //         this.tickebookingReceipt =log;
    //         this.sendOTP(this.transaction.transactionId, this.tickebookingReceipt.booking.mobileNo, this.tickebookingReceipt.booking.emailId);
    //         (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
    //         $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
    //     },error =>{
    //         bootbox.alert('Error ' + error.status);
    //     });

    //   }

    //   else if (this.transaction.eServiceId == 10) {
    //       this.receiptService.getTirupaniReceipt(this.transactionId).subscribe(log => {
    //           this.tirupaniReceipt = log;
    //           this.sendOTP(this.transaction.transactionId, this.tirupaniReceipt.thirupaniDonationPojo.mobileNumber, this.tirupaniReceipt.thirupaniDonationPojo.emailId);
    //           (<HTMLInputElement>document.getElementById('verifytxnid')).disabled = false;
    //           $('#verifyOtpLoading').removeClass('fa fa-spinner fa-spin');
    //       }, error => {
    //           bootbox.alert('Error ' + error.status);
    //       });
    //   }
    //   }


    //   sendOTP(txnid: string | number, mobileNumber: string, emailId: string) {
    //     let transaxtionId = txnid;
    //     this.otpMaster.emailId = mobileNumber;
    //     this.otpMaster.mobileNo = emailId;
    //     if ((<HTMLInputElement>document.getElementById("mobileoption")).checked) {
    //         this.getOtpFlag = true;
    //         this.otpMasterService.generateOtpForSMS(this.otpMaster).subscribe(response => {
    //             this.getOtpFlag = false;
    //             this.responseStatus = response;
    //             if (response.responseCode == 200) {
    //                 this.otpFlag = false;
    //                 this.mobileFlag = true;
    //             } else if (response.responseCode == 101) {
    //                 bootbox.alert('Something went wrong! Try again later.');
    //             }
    //         }, error => {
    //             this.getOtpFlag = false;
    //             bootbox.alert('Otp Master Service Error! Http Status ' + error.status);
    //         });
    //     } else if ((<HTMLInputElement>document.getElementById("emailoption")).checked) {
    //         this.getOtpFlag = true;
    //         this.otpMasterService.generateOtpForEmail(this.otpMaster).subscribe(response => {
    //             this.getOtpFlag = false;
    //             this.responseStatus = response;
    //             if (response.responseCode == 200) {
    //                 this.otpFlag = false;
    //                 this.emailFlag = true;
    //             } else if (response.responseCode == 101) {
    //                 bootbox.alert('Something went wrong! Try again later.');
    //             }
    //         }, error => {
    //             this.getOtpFlag = false;
    //             bootbox.alert('Otp Master Service Error! Http Status ' + error.status);
    //         });
    //     }
    // }

    // verifyOtp(Otp: string) {
    //     this.otpMaster.otpId = this.responseStatus.id;
    //     this.otpMaster.otp = Otp;
    //     if (this.otpMaster.otp != null) {
    //         this.otpMasterService.verifyOtpById(this.otpMaster).subscribe(response => {
    //             if (response.responseCode == 200) {
    //                 this.downloadPdf();
    //             } else if (response.responseCode == 101) {
    //                 bootbox.alert('Invalid OTP');
    //             }
    //         }, error => {
    //             bootbox.alert('Verify Otp By Id Error! HTTP Status ' + error.status);
    //         });
    //     } else {
    //         bootbox.alert('Please enter the Otp..');
    //     }
    // }

    directCheck(txnid: number) {

        this.transactionId = txnid;
        if (this.tId != null) {
            this.downloadFlag = true;
            this.receiptService.getUserTransaction(this.transactionId).subscribe(log => {
                this.transaction = log;
                this.qrpojo.txnid = this.transaction.transactionId;
                this.qrpojo.txnamount = this.transaction.amount;
                this.qrpojo.txndate = this.transaction.bookedOn;
                this.qrpojo.bankrefno = this.transaction.pgBankRefNum;
                if (this.transactionId == this.transaction.transactionId) {
                    if (this.transaction.status == StaticVariable.status_2) {
                        $('#pverifyOtpLoading').removeClass('fa fa-spinner fa-spin');
                        (<HTMLInputElement>document.getElementById('print')).disabled = false;
                        $('#mtxnId').trigger("reset");
                        this.transactionIdFlag = false;
                        this.txnachk = false;
                        if (this.transaction.eServiceId == 4) {
                            this.receiptService.getRoomReceipt(this.transactionId).subscribe(log => {
                                this.roomReceipt = log;
                                this.downloadFlag = false;
                                this.directPdf();
                                this.tId = null;
                            }, error => {
                                this.downloadFlag = false;
                                bootbox.alert('Error ' + error.status);
                            });
                        } else if (this.transaction.eServiceId == 5) {
                            this.receiptService.getReceiptByTransactionId(this.transactionId).subscribe(log => {
                                this.tickebookingReceipt = log;
                                this.downloadFlag = false;
                                this.directPdf();
                                this.tId = null;

                            }, error => {
                                this.downloadFlag = false;
                                bootbox.alert('Error ' + error.status);
                            });
                        }
                        else if (this.transaction.eServiceId == 1) {
                            this.receiptService.gethundiReceipt(this.transactionId).subscribe(log1 => {
                                this.hundiReceipt = log1;
                                this.downloadFlag = false;
                                this.directPdf();
                                this.tId = null;

                            }, error => {
                                this.downloadFlag = false;
                                bootbox.alert('Error ' + error.status);
                            });
                        } else if (this.transaction.eServiceId == 2) {
                            this.receiptService.getGeneralDonationReceipt(this.transactionId).subscribe(log => {
                                this.generalReceipt = log;
                                this.downloadFlag = false;
                                this.directPdf();
                                this.tId = null;

                            }, error => {
                                this.downloadFlag = false;
                                bootbox.alert('Error ' + error.status);
                            });
                        } else if (this.transaction.eServiceId == 3) {
                            this.receiptService.getAnnathanamReceipt(this.transactionId).subscribe(log => {
                                this.annathanamReceipt = log;
                                this.downloadFlag = false;
                                this.directPdf();
                                this.tId = null;

                            }, error => {
                                this.downloadFlag = false;
                                bootbox.alert('Error ' + error.status);
                            });
                        } else if (this.transaction.eServiceId == 10) {
                            this.receiptService.getTirupaniReceipt(this.transactionId).subscribe(log => {
                                this.tirupaniReceipt = log;
                                this.downloadFlag = false;
                                this.directPdf();
                                this.tId = null;

                            }, error => {
                                this.downloadFlag = false;
                                bootbox.alert('Error ' + error.status);
                            });
                        } else if (this.transaction.eServiceId == 8) {
                            this.receiptService.getMarriageApplicationByTransactionId(this.transactionId).subscribe(log => {
                                this.marriageApplicationPdf = log;
                                this.directPdf();
                                this.downloadFlag = false;
                            }, error => {
                                this.downloadFlag = false;

                                bootbox.alert('Error ' + error.status);
                            })
                        }
                    } else {
                        if (this.transaction.status == StaticVariable.status_1) {
                            this.downloadFlag = false;
                            bootbox.alert('Your Transaction is pending..');
                        } else {
                            this.downloadFlag = false;
                            bootbox.alert('Your Transaction is failed..');
                        }
                    }
                } else {
                    this.txnachk = true;
                    this.downloadFlag = false;
                    $('#mtxnId').trigger("reset");
                    bootbox.alert('Invalid Transaction Id..');
                }
            }, error => {
                this.downloadFlag = false;
                bootbox.alert('Error ' + error.status);
            });
        } else {
            bootbox.alert('Kindly Enter the TransactionId');
        }

    }

    directPdf() {
        if (this.directPrintFlag == true) {
            this.downloadPdf();
        } else {
            bootbox.alert('Entered Otp is Wrong.. Please Check the Otp..');
        }
    }


    downloadPdf() {
        if (this.transaction.eServiceId == 1) {
            HundiDonationReceipt.printHundiReceipt(this.hundiReceipt, this.qrpojo, this.transactionId);
        } else if (this.transaction.eServiceId == 2) {
            GeneralDonationReceipt.printGeneralReceipt(this.generalReceipt, this.qrpojo, this.transactionId);
        } else if (this.transaction.eServiceId == 3) {
            AnadhaanamDonationReceipt.printAnnathanamReceipt(this.annathanamReceipt, this.qrpojo, this.transactionId);
        } else if (this.transaction.eServiceId == 4) {
            RoomBookingReceipt.printRoomBookingReceipt(this.roomReceipt, this.qrpojo, this.transactionId);
        } else if (this.transaction.eServiceId == 5) {
            TicketBookingReceipt.ticketReceiptPdf(this.tickebookingReceipt);
        } else if (this.transaction.eServiceId == 10) {
            TirupaniDonationReceipt.printTirupaniReceipt(this.tirupaniReceipt, this.qrpojo, this.transactionId);
        } else if (this.transaction.eServiceId == 8) {
            MarriageBookingReceipt.marriageReceiptPdf(this.marriageApplicationPdf, this.transactionId);
        }
    }

}

import { RoomTypePhotosPojo } from './room-type-photo.pojo';
import { LoggedUserDetailsPojo } from 'src/app/pojo/logged-user-details-pojo';

export class RoomTypeMasterPojo{

	roomTypeId:number;
	roomType:string;
	roomRegionId:number;
	roomAmount:number;
    maximumNoOfPerson:number;
	maximumNoOfRooms:number;
	maximumNoOfDays:number;
	numberOfRoomsPerPerson:number;
	tax:number;
	cottageId:number;
	onlineFlag:string;

    loggedUserDetails = new LoggedUserDetailsPojo();
	roomTypePhotosPojo: RoomTypePhotosPojo[]=[];

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DayWiseReportPojo } from 'src/app/pojo/daywise-report.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { EdonationPojo } from '../pojo/E-donation.pojo';
import { EhundiPojo } from '../pojo/E-hundi.pojo';
import { RoomBookingSearchByDateAndStatusAndRegionIdPojo } from '../pojo/RoomBookingController.pojo';
import { RoomBookingPojo } from '../pojo/RoomBooking.pojo';
import { compileBaseDefFromMetadata } from '@angular/compiler';
import { TiruppaniDonationPojo } from 'src/app/pojo/TiruppaniDonationpojo';
import { TiruppanilDonationReportPojo } from '../E-Donation/e-tiruppani/e-tiruppani.component';
import { DcbCollecionPojo } from 'src/app/pojo/dcb-collection-pojo';

@Injectable({
    providedIn: 'root'
})

export class BookingService {
    [x: string]: any;

    constructor(private httpclient: HttpClient) { }

    getOnlineDayWiseReport(from: string, to: string, type: string, id: number, lid: number): Observable<DayWiseReportPojo> {
        const url = StaticVariable.porturl + 'GetDayWiseReport/' + from + '/' + to + '/' + type + '/' + id + '/' + lid;
        return this.httpclient.get<DayWiseReportPojo>(url);
    }

    getDayWiseReportForOnlineBooking(from: string, to: string, type: string, ticketId: number, slotId: number): Observable<DayWiseReportPojo> {
        const url = StaticVariable.porturl + 'GetDayWiseReportForOnlineBooking/' + from + '/' + to + '/' + type + '/' + ticketId + '/' + slotId;
        return this.httpclient.get<DayWiseReportPojo>(url);
    }
    getAnnadhanamDonationReportByDateAndStatus(from: string, to: string, status: string, templeId: number, userId, name): Observable<EdonationPojo[]> {
        const url = StaticVariable.porturl + 'AnnathanamDonationSearchByDateAndStatus/' + from + '/' + to + '/' + status + '/' + templeId;
        let params = new HttpParams().set('userId', userId).set('name', name);
        return this.httpclient.get<EdonationPojo[]>(url, { params });
    }

    getGeneralDonationReportByDateAndStatus(from: string, to: string, status: string, templeId: number, userId, name): Observable<EdonationPojo[]> {
        const url = StaticVariable.porturl + 'GeneralDonationSearchByDateAndStatus/' + from + '/' + to + '/' + status + '/' + templeId;
        let params = new HttpParams().set('userId', userId).set('name', name);
        return this.httpclient.get<EdonationPojo[]>(url, { params });
    }

    getHundiDonationReportByDateAndStatus(from: string, to: string, status: string, templeId: number, userId, name): Observable<EhundiPojo[]> {
        const url = StaticVariable.porturl + 'EhundiSearchByDateAndStatus/' + from + '/' + to + '/' + status + '/' + templeId;
        let params = new HttpParams().set('userId', userId).set('name', name);
        return this.httpclient.get<EhundiPojo[]>(url, { params });
    }

    getTiruppaniDonationReportByDateAndStatus(from: string, to: string, status: string, templeId: number, userId, name): Observable<TiruppaniDonationPojo[]> {
        const url = StaticVariable.porturl + 'ThirupaniDonationSearchByDateAndStatus/' + from + '/' + to + '/' + status + '/' + templeId;
        let params = new HttpParams().set('userId', userId).set('name', name);
        return this.httpclient.get<TiruppaniDonationPojo[]>(url, { params });
    }

    getDcbCollectionSearchByCollectionDateAndStatus(from: string, to: string, status:string,templeId:number,userId,name): Observable<DcbCollecionPojo[]>{
       const url=StaticVariable.porturl+ 'DcbCollectionSearchByCollectionDateAndStatus/'+from+'/'+to+ '/'+ status + '/'+ templeId;
       let params =new HttpParams().set('userId',userId).set('name',name);
       return this.httpclient.get<DcbCollecionPojo[]> (url,{params});
    }

    getRoomBookingReportByStatusRegionIdDateRange(arg: RoomBookingSearchByDateAndStatusAndRegionIdPojo): Observable<RoomBookingPojo[]> {
        const url = StaticVariable.porturl + 'RoomBookingSearchByDateAndStatusAndRegionId';
        return this.httpclient.post<RoomBookingPojo[]>(url, arg);
    }

    // getOnlineDayWiseTicketReport(from:string, to:string, type:number, ticketId:number, slotId:number, status:string,locationId:number):Observable<DayWiseReportPojo>{
    //     let formData: any = new FormData();
    //     formData.append("from", from);
    //     formData.append("to", to);
    //     formData.append("type", type);
    //     formData.append("ticketId", ticketId);
    //     formData.append("slotId", slotId);
    //     formData.append("status", status);
    //     formData.append("lid", locationId);
    //     const url = StaticVariable.porturl + 'GetOnlineDayWiseReportWithStatusAndSlotId';
    //     return this.httpclient.post<DayWiseReportPojo>(url,formData);
    // }
    getOnlineDayWiseTicketReport(from: string, to: string, type, ticketId, slotId, status: string, templeId, locationId): Observable<DayWiseReportPojo> {
        let formData: any = new FormData();
        let params = new HttpParams().append('from', from).append('to', to).append('type', type).append('ticketId', ticketId).append('slotId', slotId).append('status', status).append('templeId', templeId).append('lid', locationId);
        const url = StaticVariable.porturl + 'GetOnlineDayWiseReportWithStatusAndSlotId';
        return this.httpclient.get<DayWiseReportPojo>(url, { params });
    }
    getTempleListByTempleId(templeId: number): Observable<any[]> {
        const url = StaticVariable.porturl + 'GetTempleListByTempleId/' + templeId;
        return this.httpclient.get<any[]>(url);
    }

}

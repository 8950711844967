import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { DataTableModule } from 'angular-6-datatable-cc';
import { NgbModule, NgbDatepickerKeyboardService, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgQrScannerModule } from 'angular2-qrscanner';

import { AstPaginator } from '../Utils/paginator';
import { CustomKeyboardService } from '../Utils/custom-keyboard.service';
import { CustomDateFormatter } from '../Utils/custom-date-formatter';
import { JellyBoxLoadingComponent } from './Page-Loader/jelly-box-loading/jelly-box-loading.component';

import { TicketMasterComponent } from './Master-Pages/ticket-master/ticket-master.component';
import { CheckPointMasterComponent } from './Master-Pages/check-point-master/check-point-master.component';
import { FestivalMasterComponent } from './Master-Pages/festival-master/festival-master.component';
import { BlockedMasterComponent } from './Master-Pages/blocked-master/blocked-master.component';
import { UserProfileComponent } from './Administrative/user-profile/user-profile.component';
import { UserCreationComponent } from './Administrative/user-creation/user-creation.component';
import { RoleCreationComponent } from './Administrative/role-creation/role-creation.component';
import { TicketMasterPipe } from './Master-Pages/ticket-master/ticket-master.pipe';
import { CheckPointMasterPipe } from './Master-Pages/check-point-master/check-point-master.pipe';
import { FestivalMasterPipe } from './Master-Pages/festival-master/festival-master.pipe';
import { BlockedMasterPipe } from './Master-Pages/blocked-master/blocked-master.pipe';
import { UserCreationPipe } from './Administrative/user-creation/user-creation.pipe';
import { RoleCreationPipe } from './Administrative/role-creation/role-creation.pipe';

@NgModule({
    declarations: [
        AstPaginator,
        JellyBoxLoadingComponent,
        TicketMasterComponent,
        CheckPointMasterComponent,
        FestivalMasterComponent,
        BlockedMasterComponent,
        UserProfileComponent,
        UserCreationComponent,
        RoleCreationComponent,
        TicketMasterPipe,
        CheckPointMasterPipe,
        FestivalMasterPipe,
        BlockedMasterPipe,
        UserCreationPipe,
        RoleCreationPipe,
        // DataTable
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        DataTableModule,
        NgbModule,
        AngularFontAwesomeModule,
        NgQrScannerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        DataTableModule,
        NgbModule,
        AngularFontAwesomeModule,
        NgQrScannerModule,
        AstPaginator,
        JellyBoxLoadingComponent,
        TicketMasterComponent,
        CheckPointMasterComponent,
        FestivalMasterComponent,
        BlockedMasterComponent,
        UserProfileComponent,
        UserCreationComponent,
        RoleCreationComponent,
    ],
    providers: [
        { provide: NgbDatepickerKeyboardService, useClass: CustomKeyboardService },
        { provide: NgbDateParserFormatter, useClass: CustomDateFormatter }
    ]
})

export class SharedModule { }

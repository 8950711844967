import { MRolePojo } from "src/app/pojo/role-master.pojo";

export class MUserMasterPojo {

    userId: number;
    roleId: number;
    name: string;
    userName: string;
    password: string;
    designation: string;
    mobileno: string;
    companyId: number;
    address: string;
    userStatus: string;
    loggedUserId: number;
    currentLocationId: number;
    response: string;
    code: number;
    templeId:number;

    roleKey = new MRolePojo();
    locationUserPojo: LocationUserPojo[];

}

export class LocationUserPojo {

    locationUserId: number;
    userId: number;
    locationId: number;
    status: boolean;

}

export class ManagementRolePojo {
    [x: string]: any;

    master = false;
    divotee = false;
    admin = false;
    rolemaster = false;
    usercreation = false;
    bookingform = false;
    report = false;
    verificationform = false;
    daywisereport = false;
    statusreport = false;
    bookingmaster = false;
    backup = false;
    abstractreport = false;
    android = false;
    androidlogin = false;
    ticketmaster = false;
    locationmaster = false;
    locationabstract = false;
    salesdetailsreport = false;
    salesreport = false;
    ticketingreport = false;
    locationwithdaywisereport = false;
    monthwisereport = false;
    salesabstractreport = false;
    verificationtype = false;
    tickettype = false;
    festivalmaster = false;
    checkpointmaster = false;

    /// online pojo ////
    eDonation = false;
    eHundi = false;
    eGeneral = false;
    eAnnadhanam = false;
    eTiruppani =false;

    eBooking = false;
    roombooking = false;
    ticketbooking = false;

    latestnews = false;
    gallery = false;
    poojatimetable = false;

    onlinemaster = false
    roomregion = false;
    cottagemaster = false;
    roomtype = false;
    roomtypephoto = false;
    onlineroombooking = false;
    onlinedevoteemaster = false;
    onlineticketmaster = false;
    termsandcondition = false;
    onlinecheckpointmaster = false;
    reportschedule = false;

    others = false;
    receiptstatus = false;
    receiptdownload = false;

    limits = false;
    bookinglimitation = false;
    onlinefestivalmaster = false;
    blockedmaster = false;

    OnlineReports = false;
    transactionhistory = false;
    ticketwisereport = false;
    ticketdownload = false;
    ereport = false;
    annadhanamreport = false;
    consolidatedreport = false;
    onlineabstractreport = false;
    blockedfestivalview = false;

    administration = false;
    settings = false;
    userprofile = false;
    onlineusercreation = false;
    rolecreation = false;
    dcbcollection: any;

}

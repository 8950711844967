export class  E_HundiPojo{

    hundiSerialId:number;
    transactionId:number;
	devoteeUserId:number;
	offeredDate:Date;
	offeredTime:string;
	bookedPersonName:string;
	city:string;
	postalAddress:string;
	pincode:string;
	emailId:string;
	mobileNumber:string;
	idProof:string;
	proofNumber:string;
	amount:number;
	status:string;
	receiptId:string;

}
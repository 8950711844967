import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocationPojo } from 'src/app/pojo/location.pojo';
import { StaticVariable } from 'src/app/globle.class';

@Injectable({
    providedIn: 'root'
})

export class LocationMasterService {

    constructor(private httpclient: HttpClient) { }

    getLocationAll(): Observable<LocationPojo[]> {
        const url = StaticVariable.porturl + 'LocationAll/';
        return this.httpclient.get<LocationPojo[]>(url);
    }
    addLocation(arg: LocationPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddLocation';
        return this.httpclient.post(url, arg);
    }

    updateLocation(arg: LocationPojo): Observable<any> {
        const url = StaticVariable.porturl + 'LocationUpdate';
        return this.httpclient.put(url, arg);
    }

    deleteLocation(id: number): Observable<any> {
        const url = StaticVariable.porturl + 'LocationDelete/' + id;
        return this.httpclient.delete<any>(url);
    }

}

import { DummyRoomBookingTransactionPojo } from '../receipt-pojo/DummyRoomBookingTransactionPojo';
import { StaticVariable } from 'src/app/globle.class';
import { qrPojo } from '../receipt/receipt-qr.pojo';

import * as moment from 'moment';

import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { ReceiptHeader } from '../extras/receipt-header-image';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class RoomBookingReceipt {


    static printRoomBookingReceipt(arg: DummyRoomBookingTransactionPojo, qrpojo: qrPojo, transactionId: number) {

        let body = [];
        let content = [];

        content.push({
            image: ReceiptHeader.receiptImg, width: 610
        });

        content.push({ text: '' });

        body.push([{ text: 'ACCOMMODATION', bold: true, colSpan: 3, alignment: 'center', margin: [0, 5] }, {}, {}]);

        body.push([
            { text: 'E-RECEIPT', bold: true, colSpan: 2, alignment: 'center', margin: [0, 5] }, {},
            { qr: JSON.stringify(qrpojo), fit: 150, rowSpan: 19, alignment: 'center', margin: [0, 5] }
        ]);

        body.push(['Transaction ID', { text: arg.transaction.transactionId, bold: true }, {}]);

        body.push(['Receipt Number', { text: arg.RoomBookingPojo.receiptId, bold: true }, {}]);

        body.push(['Receipt Date', moment(arg.RoomBookingPojo.bookedDate).format('DD-MM-YYYY'), {}]);

        body.push(['Name  ', arg.RoomBookingPojo.fullName, {}]);

        body.push(['Place(city) ', arg.RoomBookingPojo.city, {}]);

        body.push(['Id Proof  ', { text: arg.RoomBookingPojo.idProof, bold: true }, {}]);

        body.push(['Id Number  ', { text: arg.RoomBookingPojo.proofNumber, bold: true }, {}]);

        body.push(['Cottage  ', arg.roomRegionName, {}]);

        body.push(['Room Type  ', arg.roomTypeName, {}]);

        body.push(['Number of Rooms  ', arg.RoomBookingPojo.noOfRooms, {}]);

        body.push(['Payment Id  ', arg.transaction.pgPayUId, {}]);

        body.push(['Bank Reference Id  ', arg.transaction.pgBankRefNum, {}]);

        body.push(['Check-In Date  ', moment(arg.RoomBookingPojo.checkInDate).format('DD-MM-YYYY'), {}]);

        body.push(['Check-In Time  ', moment(arg.RoomBookingPojo.checkInTime, 'HH:mm:ss').format('hh:mm A'), {}]);

        let new_date = moment(arg.RoomBookingPojo.checkInDate).add(arg.RoomBookingPojo.noOfDays, 'days').format('YYYY-MM-DD');

        body.push(['Check-Out Date  ', new_date, {}]);

        body.push(['Check-Out Time  ', moment(arg.RoomBookingPojo.checkInTime, 'HH:mm:ss').format('hh:mm A'), {}]);

        body.push(['Transaction Status  ', { text: arg.transaction.status, bold: true }, {}]);

        let amount = arg.transaction.amount;

        body.push(['Net Amount ', { text: '₹ ' + arg.transaction.amount + ' (' + amount + ' only)', bold: true }, {}]);

        content.push({
            'table': { 'body': body, widths: ['30%', '40%', '30%'] }, margin: [0, 20]
        });

        content.push(
            { text: 'Accommodation Booking Terms and conditions:', style: 'header', bold: true },
            {
                ul: [
                    { text: ' This allotment of room is only for 24 hours from the scheduled time on allotment date.  No Extension will be allowed.', fontSize: 10 },
                    { text: ' No accommodation will be given to single person.', fontSize: 10 },
                    { text: ' Booking shall not be postponed/ advanced/ cancelled/ refunded at any cause.', fontSize: 10 },
                    { text: ' For Each Room maximum 3 persons are allowed to stay inclusive of children above 12 years of age.  Extra Persons (Maximum 2 persons per room) are allowed on chargeable basis at the rate of Rs.30/- for non-a/c and Rs.50/- for A/c room per person.', fontSize: 10 },
                    { text: ' For Karthikeyan viduthi, Vadivelagam, Kathirvelagm illam, Kandan illam,Kadamban illam, Shanmugar Illam accommodations please contact booking cum reception office at Dhandapani Nilayam, Poonga Road, Adivaram, Palani.', fontSize: 10 },
                    { text: ' For Valli illam, Mayil illam & A-block non- A/C accommodations please contact reception office at Chinnakumarar Viduthi, Winch Station Opposite, West Giri Street, Adivaram, Palani.', fontSize: 10 },
                    { text: ' For Velavan Viduthi accommodations please contact reception office at Velavan Viduthi, Railway Feedar Road, Palani.', fontSize: 10 },
                    { text: ' Devotees are advised to bring the print out of booking receipt with Original Id proof which is used during the time of booking.', fontSize: 10 },
                    { text: ' This ticket is booked on a personal user id and cannot be booked by an agent.  If bought from an agent by any individual it will not be allowed.', fontSize: 10 },
                    { text: ' Devotees are advised not to carry any inflammable/ explosive/dangerous materials as part of their luggage.', fontSize: 10 },
                    { text: ' Accommodation booking should be done 3 days in advance by online.', fontSize: 10 },
                    { text: ' If you do not get the print out receipt after successful transaction you may contact the following numbers for further details.', fontSize: 10 },
                    { text: 'For Support Contact :  Mail id: ' + StaticVariable.mail+ ', HelpLine Landline Number : ' + StaticVariable.landLine + ', HelpLine Toll Free Number : ' + StaticVariable.tollFree, fontSize: 10 },
                    { text: 'An advance amount will be collected before checkIn', fonSize: 10 },
                ]
            }
        );

        const dd = {
            'content': content,
            pageMargins: [20, 5, 20, 5]
        };

        pdfMake.createPdf(dd).download('Receipt - ' + transactionId);
        pdfMake.createPdf(dd).open();

    }

}

export class StaticVariable {



  //  static porturl = 'http://192.168.0.10:8081/TicketBookingApi/';
//    static printporturl = 'http://192.168.0.10:8081/TicketBookingApi/';

  static porturl = 'http://148.66.133.79:8080/matrimony/';
 static printporturl = 'http://192.168.0.10:8083/TicketBookingApi/';

  // static porturl = 'http://192.168.0.115:8081/TicketBookingApi/';
  // static printporturl = 'http://192.168.0.115:8081/TicketBookingApi/';

  // static porturl = 'http://eticket.devanathaswamytemple.org:8080/ThiruvanthipuramTicketBookingApi/';
  // static printporturl = 'http://eticket.devanathaswamytemple.org:8080/ThiruvanthipuramTicketBookingApi/';

  // static porturl = 'http://ticket.devanathaswamytemple.org:8080/ThiruvanthipuramMarriageBookingApi/';
  // static printporturl = 'http://ticket.devanathaswamytemple.org:8080/ThiruvanthipuramMarriageBookingApi/';



  static loginTitle = 'TICKET BOOKING SOFTWARE';
  static loginPlace = 'THIRUVANDHIPURAM - 607 401';

  static templeNameEnglish = 'ARULMIGU DEVANATHASWAMY TEMPLE';
  static placeNameEnglish = 'THIRUVANDHIPURAM - 607 401';

  static templeNameTamil = 'தேவநாதசுவாமி கோவில்  ';
  static placeNameTamil = ' திருவந்திபுரம் - 607 401';

  static pageWidth = 230; // 297
  static pageHeight = 'auto'; //280
  static marginLeft = 10; //5
  static marginRight = 10; //5
  static marginTop = 10;
  static marginBottom = 10;
  static recColWidth1 = 34; //%
  static recColWidth2 = 10; //%
  static recColWidth3 = 56; //%
  static receiptHeader = true;
  static prebookingDuration = 2;
  static preBookingTime = '05:00';

  static printerName = 'EPSON TM-T82 Receipt';
  static printPreview = false;

  static status_1 = 'INITIAL';
  static status_2 = 'SUCCESS';

  static mail = '';
  static landLine = '0424 226 7578';
  static tollFree = '';

}



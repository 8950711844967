import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { StaticVariable } from 'src/app/globle.class';

@Injectable({
    providedIn: 'root'
})

export class UserCreationService {

    constructor(private httpclient: HttpClient) { }

    getManagementUser(): Observable<MUserMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetMUserMasterAll';
        return this.httpclient.get<MUserMasterPojo[]>(url);
    }

    checkUserName(userName: string): Observable<any> {
        const url = StaticVariable.porturl + 'CheckMUserName/' + userName;
        return this.httpclient.post(url, null);
    }

    addManagementUser(arg: MUserMasterPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddMUserMaster';
        return this.httpclient.post(url, arg);
    }

    deleteManagementUser(userId: number, loggedUserId: number,companyId: number): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteMUserMaster/' + userId + '/' + loggedUserId +'/'+companyId;
        return this.httpclient.delete<any>(url);
    }

    updateManagementUser(data: MUserMasterPojo): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateMUserMaster';
        return this.httpclient.put(url, data);
    }

    getMUserMasterById(id: number): Observable<MUserMasterPojo> {
        const url = StaticVariable.porturl + 'MUserMasterById/' + id;
        return this.httpclient.get<MUserMasterPojo>(url);
    }

}

import { Pipe, PipeTransform } from '@angular/core';

declare var _: any;

@Pipe({
    name: 'festivalMaster'
})

export class FestivalMasterPipe implements PipeTransform {

    transform(array: any[], query: string): any {
        if (query) {
            if (_.filter(array, row => row.festivalName.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
                return _.filter(array, row => row.festivalName.toLowerCase().indexOf(query.toLowerCase()) > -1);
            } else if (_.filter(array, row => row.festivalDescription.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
                return _.filter(array, row => row.festivalDescription.toLowerCase().indexOf(query.toLowerCase()) > -1);
            } else {
                return;
            }
        }
        return array;
    }

}

import { Component, OnInit } from '@angular/core';
import { CheckPointMasterPojo } from 'src/app/pojo/checkpoint-master-pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { CheckPointMasterService } from '../../Services/check-point-master.service';

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-check-point-master',
    templateUrl: './check-point-master.component.html',
    styleUrls: ['./check-point-master.component.scss']
})

export class CheckPointMasterComponent implements OnInit {

    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    addchk = new CheckPointMasterPojo();
    checkArr: CheckPointMasterPojo[] = [];
    loggedUser = new MUserMasterPojo();
    editchk = new CheckPointMasterPojo();

    constructor(private checkPointMasterService: CheckPointMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

        $('#addAfterClick').hide();
        $('#editAfterClick').hide();
        $('#deleteAfterClick').hide();
        this.getCheckPointAll();
    }
    getCheckPointAll() {
        this.checkPointMasterService.getCheckPoint().subscribe(log => {
            this.isLoading = false;
            this.checkArr = log;
        }, error => {
            bootbox.alert('Error');
            this.isLoading = false;
        });
    }

    addClass() {
        (<HTMLInputElement>document.getElementById('addButton')).disabled = true;
        $('#addBeforeClick').hide(); $('#addAfterClick').show();
        this.checkPointMasterService.addCheckPoint(this.addchk).subscribe(response => {
            bootbox.alert('Successfully Added');
            (<HTMLFormElement>document.getElementById('addDevoteeForm')).reset();
            $('#addModal').modal('hide');
            this.getCheckPointAll();
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;

        }, error => {
            bootbox.alert('Add Service Error! - HTTP Status ' + error.status);
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
        });
    }


    clickEdit(arg) {
        this.editchk = Object.assign({}, arg);


    }

    editClass() {
        (<HTMLInputElement>document.getElementById('editButton')).disabled = true;
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        this.checkPointMasterService.updateCheckPoint(this.editchk).subscribe(response => {

            bootbox.alert('Successfully updated.');
            $('#editModal').modal('hide');
            this.getCheckPointAll();
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();

        }, error => {
            bootbox.alert('Update Devotee Service Error! - HTTP Status ' + error.status);
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
        });
    }

    delete(arg) {
        this.id = arg;
    }

    deleteConfirm() {
        $('#deleteBeforeClick').hide(); $('#deleteAfterClick').show();
        this.checkPointMasterService.deleteCheckPoint(this.id).subscribe(response => {
            let no = response;
            if (no == 0) {
                bootbox.alert('This row is already deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } if (no == 101) {
                bootbox.alert('You cannot Delete this checkpoint linked to someother!');
            }
            else {
                bootbox.alert('Successfully deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            }
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            $('#deleteModal').modal('hide');
            this.getCheckPointAll();
        }, error => {
            bootbox.alert('Delete  Service Error! - HTTP Status ' + error.status);
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }


}


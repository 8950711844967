import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OtpMasterPojo } from '../receipt-pojo/otp-master.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { ResponseStatusPojo } from './response-status.pojo';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtpMasterService {

  constructor(private httpClient: HttpClient) { }

  generateOtpForSMS(arg: OtpMasterPojo): Observable<ResponseStatusPojo> {
      const url = StaticVariable.porturl + 'GenerateOtpForSMS';
      return this.httpClient.post<ResponseStatusPojo>(url, arg);
  }

  generateOtpForEmail(arg: OtpMasterPojo): Observable<ResponseStatusPojo> {
      const url = StaticVariable.porturl + 'GenerateOtpForEmail';
      return this.httpClient.post<ResponseStatusPojo>(url, arg);
  }

  verifyOtpById(arg: OtpMasterPojo): Observable<ResponseStatusPojo> {
      const url = StaticVariable.porturl + 'VerifyOtpById';
      return this.httpClient.post<ResponseStatusPojo>(url, arg);
  }
}

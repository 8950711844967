export class OtpMasterPojo {

    otpId: number;
    relativeId: number;
    mobileNo: string;
    otpGeneratedTime: Date;
    description: string;
    otp: string;
    otpType: string;
    emailId: string;

}
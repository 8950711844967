import { LoggedUserDetailsPojo } from './logged-user-details-pojo';

export class BlockedDateMasterPojo {

    blockedSerialId: number;
    eServiceType: string;
    eServiceId: number;
    typeId: number;
    typeId2: number;
    blockedReason: string;
    blockFrom: Date;
    blockTo: Date;
    loggedUserDetails = new LoggedUserDetailsPojo();

}

export class UserTransactionHistoryPojo{


    transactionId:number;
	devoteeUserId:number;
	bookedOn:Date;
	bookedTime:string;
	bookingDate:Date;
	accountType:string;
	amount:number;
	status:string;
	accountId:number;
	pgProviderName:string;
	receiptId:string;
	eServiceType:string;
	eServiceId:number;
	pgPayUId:string;
	pgMode:string;
	pgAccountKey:string;
	transactionInfo:string;
	firstName:string;
	pgError:string;
	pgBankCode:string;
	pgType:string;
	pgBankRefNum:string;
	pgUnMappedStatus:string;
	email:string;
	clientIpAddress:string;
	paymentType:string;
}
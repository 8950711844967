export class LoggedUserDetailsPojo {

    loggedUserId: number;
    transactionFlag: string;
    transactionDateTime: Date;
    transactionIp: string;
    name: string;
    userName: string;
    regionId: number;

}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-jelly-box-loading',
    templateUrl: './jelly-box-loading.component.html',
    styleUrls: ['./jelly-box-loading.component.scss']
})

export class JellyBoxLoadingComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}

import { RoomTypeMasterPojo } from "./room-type-master.pojo";
import { RoomRegionPojo } from "./RoomRegion.pojo";

export class RoomBookingPojo {

	roomBookingId:number;
	 checkInDate:Date;
	 checkInTime:string;
	 RoomName:string;
	roomTypeId:number;
	 roomRegionId:number;
	roomBookingSerialId:number;
	 bookedDate:Date;
	 bookedTime:string;
	 bookingForAccountHolderFlag:string;
	 devoteeUserId:number;
	 noOfRooms:number;
	 noOfDays:number;
	  amountPerRoom:number;
	 fullName:string;
	 postalAddress:string;
	 city:string;
	 pincode:string;
	 emailId:string;
	 mobileNumber:string;
	 idProof:string;
	 proofNumber:string;
	  amount:number;
	 transactionId:number;
     status:string;
	 receiptId:string;
   roomType = new RoomTypeMasterPojo();
   roomRegion = new RoomRegionPojo();

}

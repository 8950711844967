import { UserTransactionHistoryPojo } from './UserTransactionHistoryPojo';
import { RoomBookingPojo } from 'src/app/Online-Booking/pojo/RoomBooking.pojo';

export class DummyRoomBookingTransactionPojo{

    RoomBookingPojo = new RoomBookingPojo();
	transaction = new UserTransactionHistoryPojo();
	roomTypeName:string;
	roomRegionName:string;

}

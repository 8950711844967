import { Pipe, PipeTransform } from '@angular/core';

declare var _: any;

@Pipe({
    name: 'roleCreation'
})

export class RoleCreationPipe implements PipeTransform {

    transform(array: any[], query: string): any {
        if (query) {
            if (_.filter(array, row => row.roleId == query) != '') {
                return _.filter(array, row => row.roleId == query);
            } else if (_.filter(array, row => row.userRole.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
                return _.filter(array, row => row.userRole.toLowerCase().indexOf(query.toLowerCase()) > -1);
            } else {
                return false;
            }
        }
        return array;
    }

}

import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { MRolePojo } from 'src/app/pojo/role-master.pojo';
import { OtherService } from '../../Services/other.service';
import { UserCreationService } from '../../Services/user-creation.service';
import { RoleCreationService } from '../../Services/role-creation.service';
import { error } from 'protractor';

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    grp = new MUserMasterPojo();
    user = new MUserMasterPojo();
    viewUser = new MUserMasterPojo();

    role = new MRolePojo();
    roles: MRolePojo[] = [];

    id: number;
    aa: string;
    bb: string;
    flag: string;
    currentpwd: string;

    flagPUpdate: boolean = true;
    isLoading: boolean;

    constructor(private otherService: OtherService, private mUserMasterService: UserCreationService, private roleCreationService: RoleCreationService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
        this.getall();

    }

    onFocus() {
        $('#editButton').click();
    }

    getall() {
        this.isLoading = true;
        this.roleCreationService.getMRoleAll().subscribe(res => {
            this.isLoading = false;
            this.roles = res
        }, error => {
            this.isLoading = false;
            bootbox.alert('Error - ' + error.status);
        });
        this.isLoading = true;
        this.mUserMasterService.getMUserMasterById(this.loggedUser.userId).subscribe(rs => {
            this.isLoading = false;
            this.user = rs;
        }, error => {
            this.isLoading = false;
            bootbox.alert('Error - ' + error.status);
        });
    }

    getMRoleByName(id: number) {
        if (this.roles.find(r => r.roleId == id)) {
            return this.roles.find(r => r.roleId == id).userRole;
        } else {
            return this.id;
        }
    }

    getMRoleById(name: string) {
        if (this.roles.find(r => r.userRole == name)) {
            return this.roles.find(r => r.userRole == name).roleId;
        } else {
            return name;
        }
    }

    getMUserMaster() {
        this.mUserMasterService.getMUserMasterById(this.loggedUser.userId).subscribe(rs => {
            this.user = rs;
        });
    }

    validateMobile() {
        const contactNum = /^[6-9]\d{9}$/;
        let arg: string;
        arg = this.user.mobileno;
        if (arg.trim() != '') {
            if (arg.match(contactNum)) {
            } else {
                bootbox.alert({
                    message: 'Kindly Check The Mobile Number', className: 'text-center h5 text-danger', closeButton: false,
                    callback: () => {
                        this.user.mobileno = '';
                        $('#mobno').focus();
                    }
                })
            }
        } else {
            this.user.mobileno = '';
        }
    }

    checkPassword() {
        this.isLoading = true;
        this.currentpwd = (<HTMLInputElement>document.getElementById('cpw')).value;
        this.grp.userId = this.loggedUser.userId;
        this.otherService.checkUserpassword(this.loggedUser.userId, this.currentpwd).subscribe(rs => {
            this.isLoading = false;
            this.flag = JSON.stringify(rs);
            if (this.flag == 'false') {
                bootbox.alert('Please enter the correct password!');
            } else if (this.flag == 'true') {
                $('#passwordCheckModal').modal('hide');
                $('#passwordUpdateModal').modal('show');
                // (<HTMLInputElement>document.getElementById('cpw')).value = null;
                // this.passwordCheckModalClose();
                // (<HTMLInputElement>document.getElementById('hiddenbtn')).click();
                // this.passwordCheckModalClose();
            }
        }, error => {
            this.isLoading = false;
            bootbox.alert('Error - ' + error.status);
        });
    }

    passwordupdate() {
        this.aa = (<HTMLInputElement>document.getElementById('newpwd')).value;
        this.bb = (<HTMLInputElement>document.getElementById('confirmPassword')).value;
        let d = new MUserMasterPojo();
        d.loggedUserId = this.loggedUser.userId;
        if (this.aa == this.bb) {
            if (this.flag == 'false') {
                bootbox.alert('Please enter the correct password!');
                this.passwordCheckModalClose();
                (<HTMLInputElement>document.getElementById('newpwd')).value = null;
                (<HTMLInputElement>document.getElementById('confirmPassword')).value = null;
            } else if (this.flag == 'true') {
                d.loggedUserId = this.loggedUser.userId;
                this.isLoading = true;
                this.otherService.updateUserpassword(this.loggedUser.userId, this.aa).subscribe(() => {
                    this.isLoading = false;
                    bootbox.alert('Successfully Updated');
                    $('#saveBeforeClick').show(); $('#saveAfterClick').hide();
                    this.passwordCheckModalClose();
                    this.changePasswordModalClose();
                    (<HTMLInputElement>document.getElementById('newpwd')).value = null;
                    (<HTMLInputElement>document.getElementById('confirmPassword')).value = null;
                }, error => {
                    this.isLoading = false;
                    bootbox.alert('Error - ' + error.status);
                });
            }
        } else {
            bootbox.alert('The confirm password is incorrect!');
        }
    }

    update() {
        $('#submitBeforeClick').hide(); $('#submitAfterClick').show();
        let d = new MUserMasterPojo();
        d = this.user;
        d.name = (<HTMLInputElement>document.getElementById('name')).value;
        d.designation = (<HTMLInputElement>document.getElementById('desination')).value;
        d.mobileno = (<HTMLInputElement>document.getElementById('mobno')).value;
        d.address = (<HTMLInputElement>document.getElementById('address')).value;
        d.userName = (<HTMLInputElement>document.getElementById('username')).value;
        d.roleId = Number(this.getMRoleById((<HTMLInputElement>document.getElementById('roleId')).value));
        d.loggedUserId = this.loggedUser.userId;
        this.isLoading = true;
        this.mUserMasterService.updateManagementUser(d).subscribe(rs => {
            this.isLoading = false;
            $('#submitBeforeClick').show(); $('#submitAfterClick').hide();
            bootbox.alert('Successfully updated');
            $('#editLoading').removeClass('fa fa-spinner fa-spin');
        }, error => {
            this.isLoading = false;
            bootbox.alert('Error - ' + error.status);
        });
    }

    changePasswordModalClose() {
        $('#passwordUpdateModal').modal('hide');
    }

    passwordCheckModalClose() {
        $('#passwordCheckModal').modal('hide');
    }

}

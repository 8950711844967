
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { DummyDonationTransactionPojo } from '../receipt-pojo/DummyDonationTransactionPojo';
import { StaticVariable } from 'src/app/globle.class';
import { qrPojo } from '../receipt/receipt-qr.pojo';
import { ReceiptHeader } from '../extras/receipt-header-image';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class GeneralDonationReceipt {

    static printGeneralReceipt(arg: DummyDonationTransactionPojo, qrpojo: qrPojo, transactionId: number) {

        let body = [];
        let content = [];

        content.push({
            image: ReceiptHeader.receiptImg, width: 610
        });

        content.push({ text: '' });

        body.push([{ text: 'GENERAL DONATION', bold: true, colSpan: 3, alignment: 'center', margin: [0, 5] }, {}, {}]);

        body.push([
            { text: 'E-RECEIPT', bold: true, colSpan: 2, alignment: 'center', margin: [0, 5] }, {},
            { qr: JSON.stringify(qrpojo), fit: 150, rowSpan: 12, alignment: 'center', margin: [0, 5] }
        ]);

        body.push(['Transaction ID  ', { text: arg.transaction.transactionId, bold: true }, {}]);

        body.push(['Receipt Number  ', { text: arg.transaction.receiptId, bold: true }, {}]);

        body.push(['Receipt Date  ', moment(arg.donation.donateDate).format('DD-MM-YYYY'), {}]);

        body.push(['Name  ', arg.donation.bookedPersonName, {}]);

        body.push(['Place(city)  ', arg.donation.city, {}]);

        body.push(['Id Proof  ', { text: arg.donation.idProof, bold: true }, {}]);

        body.push(['Id Number  ', { text: arg.donation.proofNumber, bold: true }, {}]);

        body.push(['Payment Id  ', arg.transaction.pgPayUId, {}]);

        body.push(['Bank Reference Id  ', arg.transaction.pgBankRefNum, {}]);

        body.push(['Transaction Status  ', { text: arg.transaction.status, bold: true }, {}]);

        let amount = arg.transaction.amount;

        body.push(['Net Amount ', { text: '₹ ' + arg.transaction.amount + ' (' + amount + ' only)', bold: true }, {}]);

        content.push({
            'table': { 'body': body, widths: ['30%', '40%', '30%'] }, margin: [0, 20]
        });

        content.push(
            { text: 'General Donation Terms and conditions:', style: 'header', bold: true },
            {
                ul: [
                    { text: ' If you do not get the print out receipt after successful transaction you may contact the following numbers for further details.' },
                    { text: 'For Support Contact :  Mail id: ' + StaticVariable.mail + ', HelpLine Landline Number : ' + StaticVariable.landLine + ', HelpLine Toll Free Number : ' + StaticVariable.tollFree, fontSize: 10 },
                ]
            }
        );

        const dd = {
            'content': content,
            pageMargins: [20, 5, 20, 5]
        };

        pdfMake.createPdf(dd).download('Receipt - ' + transactionId);
        pdfMake.createPdf(dd).open();

    }

}

import { Component, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { FestivalPojo } from 'src/app/pojo/festival.pojo';
import { FestivalMasterService } from '../../Services/festival-master.service';
import { UtilsFunction } from 'src/app/Utils/utils-function';

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-festival-master',
    templateUrl: './festival-master.component.html',
    styleUrls: ['./festival-master.component.scss']
})

export class FestivalMasterComponent implements OnInit {

    addStart: NgbDate;
    editStart: NgbDate;

    addEnd: NgbDate;
    editEnd: NgbDate;

    loggedUser = new MUserMasterPojo();

    fms: FestivalPojo[] = [];
    fm = new FestivalPojo();
    addFest = new FestivalPojo();
    editFest = new FestivalPojo();

    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor(private festivalMasterservice: FestivalMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

        $('#addAfterClick').hide();
        $('#editAfterClick').hide();
        $('#deleteAfterClick').hide();
        this.getAllFestival();

    }

    getAllFestival() {
        this.festivalMasterservice.getFestival().subscribe((log: any[]) => {
            this.isLoading = false;
            this.fms = log;
        }, (error: { status: string; }) => {
            bootbox.alert('Error' + error.status);
            this.isLoading = false;
        });
    }

    addClass() {

        (<HTMLInputElement>document.getElementById('addButton')).disabled = true;
        $('#addBeforeClick').hide(); $('#addAfterClick').show();
        if (this.addStart != null && this.addStart != undefined) {
            this.addFest.festivalStartDate = UtilsFunction.getDateByNgbDate(this.addStart);
        } if (this.addEnd != null && this.addEnd != undefined) {
            this.addFest.festivalEndDate = UtilsFunction.getDateByNgbDate(this.addEnd);
        } this.festivalMasterservice.addFestival(this.addFest).subscribe((response: any) => {
            bootbox.alert('Successfully Added');
            (<HTMLFormElement>document.getElementById('addDevoteeForm')).reset();
            $('#addModal').modal('hide');
            this.getAllFestival();
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;

        }, (error: { status: string; }) => {
            bootbox.alert('Add Service Error! - HTTP Status ' + error.status);
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
        });

    }

    clickEdit(arg: any) {
        this.editFest = Object.assign({}, arg);
        if (this.editFest.festivalStartDate != null && this.editFest.festivalStartDate != undefined) {
            this.editStart = UtilsFunction.getNgbDateByDateObject(this.editFest.festivalStartDate);
        } if (this.editFest.festivalEndDate != null && this.editFest.festivalEndDate != undefined) {
            this.editEnd = UtilsFunction.getNgbDateByDateObject(this.editFest.festivalEndDate);
        }
    }

    editClass() {
        let editFestival = new FestivalPojo();
        if (this.editStart != null && this.editStart != undefined) {
            this.editFest.festivalStartDate = UtilsFunction.getDateByNgbDate(this.editStart);
        }
        if (this.editEnd != null && this.editEnd != undefined) {
            this.editFest.festivalEndDate = UtilsFunction.getDateByNgbDate(this.editEnd);
        } this.editFest.loggedUserDetails = this.loggedUser;
        editFestival = this.editFest;
        (<HTMLInputElement>document.getElementById('editButton')).disabled = true;
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        this.festivalMasterservice.updateFestival(editFestival).subscribe((response: any) => {

            bootbox.alert('Successfully updated.');
            $('#editModal').modal('hide');
            this.getAllFestival();
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();

        }, (error: { status: string; }) => {
            bootbox.alert('Update Devotee Service Error! - HTTP Status ' + error.status);
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
        });
    }

    delete(arg: number) {
        this.id = arg;
    }

    deleteConfirm() {
        $('#deleteBeforeClick').hide(); $('#deleteAfterClick').show();
        this.festivalMasterservice.deleteFestival(this.id).subscribe((response: any) => {
            let no = response;
            if (no == 0) {
                bootbox.alert('This row is already deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } else {
                bootbox.alert('Successfully deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            }
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            $('#deleteModal').modal('hide');
            this.getAllFestival();
        }, (error: { status: string; }) => {
            bootbox.alert('Delete Devotee Service Error! - HTTP Status ' + error.status);
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from 'src/app/globle.class';
import { ResponsePojo } from 'src/app/pojo/response.pojo';
import { MarriageSlotMasterPojo } from '../pojo/marriage-slot-master.pojo';

@Injectable({
  providedIn: 'root'
})
export class MarriageBookingSlotService {
  
  constructor(private httpClient:HttpClient) { }


  getMarriageSlotMasterAll():Observable<MarriageSlotMasterPojo[]>{
    const url = StaticVariable.porturl + 'MarriageSlotMasterAll';
    return this.httpClient.get<MarriageSlotMasterPojo[]>(url);
  }

  addMarriageSlotMaster(arg:MarriageSlotMasterPojo):Observable<ResponsePojo>{
    const url = StaticVariable.porturl + 'AddMarriageSlotMaster';
    return this.httpClient.post<ResponsePojo>(url,arg);
  }

  updateMarriageSlotMaster(arg:MarriageSlotMasterPojo):Observable<boolean>{
    const url = StaticVariable.porturl + 'UpdateMarriageSlotMaster';
    return this.httpClient.put<boolean>(url,arg);
  }


  deleteMarriageSlotMaster(id:number):Observable<number>{
    const url = StaticVariable.porturl + 'DeleteMarriageSlotMaster/' + id;
    return this.httpClient.delete<number>(url);
  }
  

  getMarriageSlotMasterById(id:number):Observable<MarriageSlotMasterPojo>{
    const url = StaticVariable.porturl + 'MarriageSlotMasterById/' + id;
    return this.httpClient.delete<MarriageSlotMasterPojo>(url)
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CheckPointMasterPojo } from 'src/app/pojo/checkpoint-master-pojo';
import { StaticVariable } from 'src/app/globle.class';

@Injectable({
    providedIn: 'root'
})

export class CheckPointMasterService {

    constructor(private httpclient: HttpClient) { }

    getCheckPoint(): Observable<CheckPointMasterPojo[]> {
        const url = StaticVariable.porturl + 'CheckPointMasterAll';
        return this.httpclient.get<CheckPointMasterPojo[]>(url);
    }

    addCheckPoint(arg: CheckPointMasterPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddCheckPointMaster';
        return this.httpclient.post(url, arg);
    }

    deleteCheckPoint(id: number): Observable<any> {
        const url = StaticVariable.porturl + 'CheckPointMasterDelete/' + id;
        return this.httpclient.delete<any>(url);
    }

    updateCheckPoint(data: CheckPointMasterPojo): Observable<any> {
        const url = StaticVariable.porturl + 'CheckPointMasterUpdate';
        return this.httpclient.put(url, data);
    }

}

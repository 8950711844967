import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TicketBookingLimitationsPojo } from 'src/app/pojo/ticket-booking-limitations.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { ResponsePojo } from 'src/app/pojo/response.pojo';
import { BookingLimitationsPojo } from 'src/app/pojo/Booking-limitations.pojo';

@Injectable({
    providedIn: 'root'
})

export class TicketBookingLimitationService {

    constructor(private httpclient: HttpClient) { }

    getBookingLimitation(): Observable<BookingLimitationsPojo[]> {
        const url = StaticVariable.porturl + 'BookingLimitationsAll';
        return this.httpclient.get<BookingLimitationsPojo[]>(url);
    }

    addBookingLimitation(arg: TicketBookingLimitationsPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'AddTicketBookingLimitations';
        return this.httpclient.post<ResponsePojo>(url, arg);
    }

    deleteBookingLimitation(arg: number): Observable<any> {
        const url = StaticVariable.porturl + 'TicketBookingLimitationsDelete/' + arg;
        return this.httpclient.delete<any>(url);
    }

    updateTicketBookingLimitation(arg: TicketBookingLimitationsPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'TicketBookingLimitationsUpdate';
        return this.httpclient.put<ResponsePojo>(url, arg);
    }
    updateBookingLimitation(arg: BookingLimitationsPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'BookingLimitationsUpdate';
        return this.httpclient.put<ResponsePojo>(url, arg);
    }

    getTicketBookingLimitation(): Observable<TicketBookingLimitationsPojo[]> {
        const url = StaticVariable.porturl + 'TicketBookingLimitationsAll';
        return this.httpclient.get<TicketBookingLimitationsPojo[]>(url);
    }

}

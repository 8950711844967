export class E_DonationPojo{

    donationSerialId:number;
	transactionId:number;
	devoteeUserId:number;
	donateDate:Date;
	donateTime:string;
	donationType:string;
	// @JsonFormat(shape = JsonFormat.Shape.STRING, locale = "en_IN")
	annathanamDate:Date;
	bookedPersonName:string;
	city:string;
	postalAddress:string;
	pincode:string;
	emailId:string;
	mobileNumber:string;
	idProof:string;
	proofNumber:string;
	amount:number;
	status:string;
	receiptId:string;


}
import { UserTransactionHistoryPojo } from '../receipt/receipt-pojo/UserTransactionHistoryPojo';
import { BookingPojo } from './booking-pojo';
import { ResponsePojo } from './response.pojo';
import { OnlineTicketDetPojo } from './OnlineTicketDet-Pojo';

export class TransactionTicketBookingPojo {

    transaction = new UserTransactionHistoryPojo();
    booking = new BookingPojo();
    res = new ResponsePojo();
    rtc: OnlineTicketDetPojo[] = [];
}
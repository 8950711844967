import { LoggedUserDetailsPojo } from 'src/app/pojo/logged-user-details-pojo';

export class RoomRegionPojo {
    roomRegionId:number;
	regionName:string;
	regionAddress:string;
	regionConductNumber:string;
	totalNumberOfRooms:number;
	loggedUserDetails = new LoggedUserDetailsPojo()
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlockedDateMasterPojo } from 'src/app/pojo/blocked-date-master.pojo';
import { Observable } from 'rxjs';
import { ResponsePojo } from 'src/app/pojo/response.pojo';
import { StaticVariable } from 'src/app/globle.class';

@Injectable({
    providedIn: 'root'
})

export class BlockedMasterService {

    constructor(private httpclient: HttpClient) { }

    addBlockedDateMaster(arg: BlockedDateMasterPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'AddBlockedDateMaster';
        return this.httpclient.post<ResponsePojo>(url, arg);
    }

    blockedDateMasterUpdate(arg: BlockedDateMasterPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'BlockedDateMasterUpdate';
        return this.httpclient.put<ResponsePojo>(url, arg);
    }

    blockedDateMasterDelete(id: number): Observable<number> {
        const url = StaticVariable.porturl + 'BlockedDateMasterDelete/' + id;
        return this.httpclient.delete<number>(url);
    }

    blockedDateMasterAll(): Observable<BlockedDateMasterPojo[]> {
        const url = StaticVariable.porturl + 'BlockedDateMasterAll';
        return this.httpclient.get<BlockedDateMasterPojo[]>(url);
    }

    blockedDateMasterById(blockedSerialId: number): Observable<BlockedDateMasterPojo> {
        const url = StaticVariable.porturl + 'BlockedDateMasterById/' + blockedSerialId;
        return this.httpclient.get<BlockedDateMasterPojo>(url);
    }

}

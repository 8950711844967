import { LoggedUserDetailsPojo } from "src/app/pojo/logged-user-details-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";

export class FestivalPojo {
    festivalId: number;
    festivalName: string;
    festivalStartDate: Date;
    festivalEndDate: Date;
    festivalDescription: string;
    festivalBookingBlockFlag: string;
    loggedUserDetails = new MUserMasterPojo();
}
